import { ErrorCode } from "../hooks/types";
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from "./token";
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";

export type Violation = { message: string; property: string };
export type DecodedToken = {
  exp: number;
  iat: number;
  jti: string;
  nbf: number;
  role: string;
  sub: string;
  type: string;
};

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit["headers"]
): (() => Promise<TData>) => {
  return async () => {
    const token = getAccessToken();
    if (token) {
      const decoded = jwt_decode(token) as DecodedToken;

      const currentTimeInMilis = DateTime.fromMillis(Date.now())
        .startOf("second")
        .toMillis()
        .toString();

      const currentTimeFormatted = parseInt(
        currentTimeInMilis.substring(0, currentTimeInMilis.length - 3)
      );
      const expTime = decoded.exp;

      if (currentTimeFormatted > expTime) {
        const refreshMutation =
          "\n    mutation refreshAccessToken($refreshToken: String!) {\n  identity {\n    refreshAccessToken(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n      identity {\n        id\n        email\n        name\n      }\n    }\n  }\n}\n  ";

        const refreshTokenRes = await fetch(process.env.REACT_APP_SCHEMA_URL || "", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
            ...options,
          },
          body: JSON.stringify({
            query: refreshMutation,
            variables: { refreshToken: getRefreshToken() },
          }),
        });

        const response = await refreshTokenRes.json();

        if (!response.error) {
          const { accessToken, refreshToken } = response.data.identity.refreshAccessToken;
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
        }
      }
    }

    const res = await fetch(process.env.REACT_APP_SCHEMA_URL || "", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const error = json.errors[0];
      if (error.extensions.code === ErrorCode.Validation) {
        let messages: string[] = [];
        error.extensions.violations.forEach((violation: Violation) => {
          messages.push(violation.message);
        });

        throw new Error(messages.join("\n"), {
          cause: error.extensions.code,
        });
      } else {
        throw new Error(error.message, { cause: error.extensions.code });
      }
    }

    return json.data;
  };
};
