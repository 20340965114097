import { createContext, ReactNode, useContext, useState } from "react";
import {
  LoginMutation,
  RefreshAccessTokenMutation,
  Role,
  useLoginMutation,
  useLogoutMutation,
  useRefreshAccessTokenMutation,
} from "../../graphql/client";
import { useNavigate } from "react-router-dom";
import { LoginData } from "./AuthContext.types";
import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../../helpers/token";
import { useHandleError } from "../../hooks/useHandleError";

export type Auth = {
  isLoggedIn: boolean;
  logout: () => void;
  login: (loginObj: LoginData) => void;
  isLoading: boolean;
  error: string;
  handleUnauthorized: () => void;
  refreshAccessToken: () => void;
  setUserAccount: (account: AccountDetails) => void;
};

export type AccountDetails = { id: string; name?: string | null; email: string };

export type AuthContextProviderProps = { children: ReactNode };

export const AuthContext = createContext<Auth>({
  isLoggedIn: false,
  logout: () => undefined,
  login: () => undefined,
  isLoading: false,
  error: "",
  handleUnauthorized: () => undefined,
  setUserAccount: () => undefined,
  refreshAccessToken: () => undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);
  const handleError = useHandleError();
  const { mutate: loginMutate, isLoading: isLoadingLogin } = useLoginMutation({
    onSuccess: (res: LoginMutation) => handleLoginSuccess(res),
    onError: (e: Error) => handleLoginError(e),
  });

  const { mutate: refreshTokenMutate, isLoading: isLoadingRefreshToken } =
    useRefreshAccessTokenMutation({
      onSuccess: (res: RefreshAccessTokenMutation) => handleRefreshSuccess(res),
      onError: (e: Error) => handleRefreshError(e),
    });

  const {
    mutate: logoutMutate,
    isLoading: isLoadingLogout,
    error: errorLogout,
  } = useLogoutMutation({
    onError: (e: Error) => handleLogoutError(e),
    onSuccess: () => handleLogoutSuccess(),
  });

  const reset = () => {
    setAccountDetails(null);
    removeAccessToken();
    removeRefreshToken();
    navigate("/login");
  };

  const handleUnauthorized = () => {
    reset();
  };

  const login = ({ email, password }: LoginData) => {
    const loginData = {
      input: { email: email, password: password, loginAs: Role.Admin },
    };
    loginMutate(loginData);
  };

  const logout = () => {
    logoutMutate({});
  };

  const refreshAccessToken = async () => {
    if (!isLoadingRefreshToken) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        refreshTokenMutate({ refreshToken: refreshToken });
      } else {
        handleUnauthorized();
      }
    }
  };

  const handleLoginSuccess = (res: LoginMutation) => {
    const accessToken = res.identity.login.accessToken;
    const refreshToken = res.identity.login.refreshToken;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    const details = res.identity.login.identity;
    const { id, name = "", email = "" } = details;
    setAccountDetails({ id, name, email });
    navigate("/");
  };

  const handleLoginError = (error: Error) => {
    setLoginError(error.message);
  };

  const handleRefreshSuccess = (res: RefreshAccessTokenMutation) => {
    const refreshToken = res.identity.refreshAccessToken.refreshToken;
    const accessToken = res.identity.refreshAccessToken.accessToken;
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
    const details = res.identity.refreshAccessToken.identity;
    const { id, name = "", email = "" } = details;
    setAccountDetails({ id, name, email });
  };

  const handleRefreshError = (error: Error) => {
    handleUnauthorized();
  };

  const handleLogoutSuccess = () => {
    reset();
    window.location.reload();
  };

  const handleLogoutError = (e: Error) => {
    handleError(e);
  };

  const setUserAccount = (account: AccountDetails) => {
    setAccountDetails(account);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(accountDetails !== null),
        logout,
        login,
        isLoading: isLoadingLogin || isLoadingLogout || isLoadingRefreshToken,
        error: loginError || errorLogout?.toString() || "",
        handleUnauthorized,
        refreshAccessToken,
        setUserAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
