import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from '../../helpers/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BasicEducationCategoryId: any;
  BasicEducationPostId: any;
  DiaryId: any;
  Duration: any;
  FrequentlyAskedQuestionId: any;
  GeneralRecommendationId: any;
  GoalId: any;
  GoalSuggestionId: any;
  IdentityId: any;
  Instant: any;
  LocalDate: any;
  LocalTime: any;
  Long: any;
  SleepAidPostId: any;
  SleepHabitSuggestionId: any;
  TreatmentId: any;
  TreatmentSleepAdviceId: any;
  TreatmentVideoId: any;
  TreatmentWeekId: any;
};

export type AddPushNotificationTokenInput = {
  identityId: Scalars['IdentityId'];
  token: Scalars['String'];
};

export type AddSleepAdviceToTreatmentWeekInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  weekId: Scalars['TreatmentWeekId'];
};

export type AddVideoToTreatmentWeekInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  englishVideoDuration: Scalars['Duration'];
  englishVideoThumbnailUrl: Scalars['String'];
  englishVideoUrl: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  icelandicVideoDuration: Scalars['Duration'];
  icelandicVideoThumbnailUrl: Scalars['String'];
  icelandicVideoUrl: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  weekId: Scalars['TreatmentWeekId'];
};

export type AddWeekToTreatmentInput = {
  number: Scalars['Int'];
  treatmentId: Scalars['TreatmentId'];
};

export type AuthMutationResponse = {
  __typename?: 'AuthMutationResponse';
  /** Access Token */
  accessToken: Scalars['String'];
  identity: Identity;
  /** Refresh Token */
  refreshToken: Scalars['String'];
  user: User;
};

export type AuthenticateWithExternalIdentityProviderInput = {
  externalIdentityProvider: ExternalIdentityProvider;
  platformType: PlatformType;
  token: Scalars['String'];
};

export type BanInput = {
  identityId: Scalars['IdentityId'];
};

/** Basic education category */
export type BasicEducationCategory = {
  __typename?: 'BasicEducationCategory';
  /** Basic education category background image url */
  backgroundImageUrl: Scalars['String'];
  /** Basic education category unique identifier */
  id: Scalars['BasicEducationCategoryId'];
  /** Basic education category name */
  name: Scalars['String'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** Basic education category details */
export type BasicEducationCategoryDetails = {
  __typename?: 'BasicEducationCategoryDetails';
  /** Basic education category background image url */
  backgroundImageUrl: Scalars['String'];
  /** Language */
  language: Language;
  /** Basic education category name */
  name: Scalars['String'];
};

/** List of basic education categories */
export type BasicEducationCategoryList = {
  __typename?: 'BasicEducationCategoryList';
  items: Array<BasicEducationCategory>;
  meta: ListMetadata;
};

/** Basic education category with details */
export type BasicEducationCategoryWithDetails = {
  __typename?: 'BasicEducationCategoryWithDetails';
  /** Basic education category details */
  details: Array<Maybe<BasicEducationCategoryDetails>>;
  /** Basic education category unique identifier */
  id: Scalars['BasicEducationCategoryId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of basic education category with details */
export type BasicEducationCategoryWithDetailsList = {
  __typename?: 'BasicEducationCategoryWithDetailsList';
  items: Array<BasicEducationCategoryWithDetails>;
  meta: ListMetadata;
};

/** Basic education post */
export type BasicEducationPost = {
  __typename?: 'BasicEducationPost';
  /** Basic education category unique identifier */
  basicEducationCategoryId: Scalars['BasicEducationCategoryId'];
  /** Basic education post content */
  content: Scalars['String'];
  /** Basic education post unique identifier */
  id: Scalars['BasicEducationPostId'];
  /** priority for sorting */
  priority: Scalars['Int'];
  /** Basic education post title */
  title: Scalars['String'];
};

/** Basic education post details */
export type BasicEducationPostDetails = {
  __typename?: 'BasicEducationPostDetails';
  /** Basic education post content */
  content: Scalars['String'];
  /** Language */
  language: Language;
  /** Basic education post title */
  title: Scalars['String'];
};

/** List of basic education posts */
export type BasicEducationPostList = {
  __typename?: 'BasicEducationPostList';
  items: Array<BasicEducationPost>;
  meta: ListMetadata;
};

/** Basic education post with details */
export type BasicEducationPostWithDetails = {
  __typename?: 'BasicEducationPostWithDetails';
  /** Basic education category unique identifier */
  basicEducationCategoryId: Scalars['BasicEducationCategoryId'];
  /** Basic education post details */
  details: Array<Maybe<BasicEducationPostDetails>>;
  /** Basic education post unique identifier */
  id: Scalars['BasicEducationPostId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of basic education posts with details */
export type BasicEducationPostWithDetailsList = {
  __typename?: 'BasicEducationPostWithDetailsList';
  items: Array<BasicEducationPostWithDetails>;
  meta: ListMetadata;
};

export type ChangePasswordInput = {
  identityId: Scalars['IdentityId'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

/** Chart data */
export type ChartData = {
  __typename?: 'ChartData';
  /** Name of month or date */
  date: Scalars['String'];
  /** Sleep duration */
  sleepDuration: Scalars['Duration'];
  /** Sleep efficiency */
  sleepEfficiency: Scalars['Int'];
  /** Sleep latency duration */
  sleepLatencyDuration: Scalars['Duration'];
  /** What time was user's final awakening */
  wasoDuration: Scalars['Duration'];
};

/** Child details */
export type Child = {
  __typename?: 'Child';
  /** Child's age */
  age: Scalars['Int'];
};

export type ChildInput = {
  age: Scalars['Int'];
};

export type ConfirmEmailInput = {
  token: Scalars['String'];
};

export type ContentMutationGroup = {
  __typename?: 'ContentMutationGroup';
  addSleepAdviceToTreatmentWeek: MutationResponse;
  addVideoToTreatmentWeek: MutationResponse;
  addWeekToTreatment: MutationResponse;
  createBasicEducationCategory: BasicEducationCategoryWithDetails;
  createBasicEducationPost: BasicEducationPostWithDetails;
  createFrequentlyAskedQuestion: FrequentlyAskedQuestionWithDetails;
  createGeneralRecommendation: GeneralRecommendationWithDetails;
  createGoalSuggestion: GoalSuggestionWithDetails;
  createSleepAidPost: SleepAidPostWithDetailsWithDetails;
  createSleepHabitSuggestion: SleepHabitSuggestionWithDetails;
  createTreatment: MutationResponse;
  deleteBasicEducationCategory: MutationResponse;
  deleteBasicEducationPost: MutationResponse;
  deleteFrequentlyAskedQuestion: MutationResponse;
  deleteGeneralRecommendation: MutationResponse;
  deleteGoalSuggestion: MutationResponse;
  deleteSleepAidPost: MutationResponse;
  deleteSleepHabitSuggestion: MutationResponse;
  deleteTreatment: MutationResponse;
  deleteTreatmentSleepAdvice: MutationResponse;
  deleteTreatmentVideo: MutationResponse;
  deleteTreatmentWeek: MutationResponse;
  setBasicEducationCategoryPriority: MutationResponse;
  setBasicEducationPostPriority: MutationResponse;
  setFrequentlyAskedQuestionPriority: MutationResponse;
  setGeneralRecommendationPriority: MutationResponse;
  setGoalSuggestionPriority: MutationResponse;
  setSleepAidPostPriority: MutationResponse;
  setSleepHabitSuggestionPriority: MutationResponse;
  setTreatmentPriority: MutationResponse;
  updateBasicEducationCategory: BasicEducationCategoryWithDetails;
  updateBasicEducationPost: BasicEducationPostWithDetails;
  updateFrequentlyAskedQuestion: FrequentlyAskedQuestionWithDetails;
  updateGeneralRecommendation: GeneralRecommendationWithDetails;
  updateGoalSuggestion: GoalSuggestionWithDetails;
  updateSleepAidPost: SleepAidPostWithDetailsWithDetails;
  updateSleepHabitSuggestion: SleepHabitSuggestionWithDetails;
  updateTreatment: MutationResponse;
  updateTreatmentSleepAdvice: MutationResponse;
  updateTreatmentVideo: MutationResponse;
  updateTreatmentWeek: MutationResponse;
};


export type ContentMutationGroupAddSleepAdviceToTreatmentWeekArgs = {
  input: AddSleepAdviceToTreatmentWeekInput;
};


export type ContentMutationGroupAddVideoToTreatmentWeekArgs = {
  input: AddVideoToTreatmentWeekInput;
};


export type ContentMutationGroupAddWeekToTreatmentArgs = {
  input: AddWeekToTreatmentInput;
};


export type ContentMutationGroupCreateBasicEducationCategoryArgs = {
  input: CreateBasicEducationCategoryInput;
};


export type ContentMutationGroupCreateBasicEducationPostArgs = {
  input: CreateBasicEducationPostInput;
};


export type ContentMutationGroupCreateFrequentlyAskedQuestionArgs = {
  input: CreateFrequentlyAskedQuestionInput;
};


export type ContentMutationGroupCreateGeneralRecommendationArgs = {
  input: CreateGeneralRecommendationInput;
};


export type ContentMutationGroupCreateGoalSuggestionArgs = {
  input: CreateGoalSuggestionInput;
};


export type ContentMutationGroupCreateSleepAidPostArgs = {
  input: CreateSleepAidPostInput;
};


export type ContentMutationGroupCreateSleepHabitSuggestionArgs = {
  input: CreateSleepHabitSuggestionInput;
};


export type ContentMutationGroupCreateTreatmentArgs = {
  input: CreateTreatmentInput;
};


export type ContentMutationGroupDeleteBasicEducationCategoryArgs = {
  input: DeleteBasicEducationCategoryInput;
};


export type ContentMutationGroupDeleteBasicEducationPostArgs = {
  input: DeleteBasicEducationPostInput;
};


export type ContentMutationGroupDeleteFrequentlyAskedQuestionArgs = {
  input: DeleteFrequentlyAskedQuestionInput;
};


export type ContentMutationGroupDeleteGeneralRecommendationArgs = {
  input: DeleteGeneralRecommendationInput;
};


export type ContentMutationGroupDeleteGoalSuggestionArgs = {
  input: DeleteGoalSuggestionInput;
};


export type ContentMutationGroupDeleteSleepAidPostArgs = {
  input: DeleteSleepAidPostInput;
};


export type ContentMutationGroupDeleteSleepHabitSuggestionArgs = {
  input: DeleteSleepHabitSuggestionInput;
};


export type ContentMutationGroupDeleteTreatmentArgs = {
  input: DeleteTreatmentInput;
};


export type ContentMutationGroupDeleteTreatmentSleepAdviceArgs = {
  input: DeleteTreatmentWeekSleepAdviceInput;
};


export type ContentMutationGroupDeleteTreatmentVideoArgs = {
  input: DeleteTreatmentWeekVideoInput;
};


export type ContentMutationGroupDeleteTreatmentWeekArgs = {
  input: DeleteTreatmentWeekInput;
};


export type ContentMutationGroupSetBasicEducationCategoryPriorityArgs = {
  input: SetBasicEducationCategoryPriorityInputType;
};


export type ContentMutationGroupSetBasicEducationPostPriorityArgs = {
  input: SetBasicEducationPostPriorityInputType;
};


export type ContentMutationGroupSetFrequentlyAskedQuestionPriorityArgs = {
  input: SetFrequentlyAskedQuestionPriorityInputType;
};


export type ContentMutationGroupSetGeneralRecommendationPriorityArgs = {
  input: SetGeneralRecommendationPriorityInputType;
};


export type ContentMutationGroupSetGoalSuggestionPriorityArgs = {
  input: SetGoalSuggestionPriorityInputType;
};


export type ContentMutationGroupSetSleepAidPostPriorityArgs = {
  input: SetSleepAidPostPriorityInputType;
};


export type ContentMutationGroupSetSleepHabitSuggestionPriorityArgs = {
  input: SetSleepHabitSuggestionPriorityInputType;
};


export type ContentMutationGroupSetTreatmentPriorityArgs = {
  input: SetTreatmentPriorityInput;
};


export type ContentMutationGroupUpdateBasicEducationCategoryArgs = {
  input: UpdateBasicEducationCategoryInput;
};


export type ContentMutationGroupUpdateBasicEducationPostArgs = {
  input: UpdateBasicEducationPostInput;
};


export type ContentMutationGroupUpdateFrequentlyAskedQuestionArgs = {
  input: UpdateFrequentlyAskedQuestionInput;
};


export type ContentMutationGroupUpdateGeneralRecommendationArgs = {
  input: UpdateGeneralRecommendationInput;
};


export type ContentMutationGroupUpdateGoalSuggestionArgs = {
  input: UpdateGoalSuggestionInput;
};


export type ContentMutationGroupUpdateSleepAidPostArgs = {
  input: UpdateSleepAidPostInput;
};


export type ContentMutationGroupUpdateSleepHabitSuggestionArgs = {
  input: UpdateSleepHabitSuggestionInput;
};


export type ContentMutationGroupUpdateTreatmentArgs = {
  input: UpdateTreatmentInput;
};


export type ContentMutationGroupUpdateTreatmentSleepAdviceArgs = {
  input: UpdateTreatmentSleepAdviceInput;
};


export type ContentMutationGroupUpdateTreatmentVideoArgs = {
  input: UpdateTreatmentVideoInput;
};


export type ContentMutationGroupUpdateTreatmentWeekArgs = {
  input: UpdateTreatmentWeekInput;
};

export type ContentQueryGroup = {
  __typename?: 'ContentQueryGroup';
  getBasicEducationCategoryBackgroundImageUploadUrl: FileUploadUrl;
  getSleepAidPostVideoThumbnailUploadUrl: FileUploadUrl;
  getSleepAidPostVideoUploadUrl: FileUploadUrl;
  getTreatmentVideoThumbnailUploadUrl: FileUploadUrl;
  getTreatmentVideoUploadUrl: FileUploadUrl;
  getTreatmentWeekWithDetails: TreatmentWeekWithDetailsContent;
  listBasicEducationCategories: BasicEducationCategoryList;
  listBasicEducationCategoriesWithDetails: BasicEducationCategoryWithDetailsList;
  listBasicEducationPostsByCategoryId: BasicEducationPostList;
  listBasicEducationPostsWithDetails: BasicEducationPostWithDetailsList;
  listFrequentlyAskedQuestions: FrequentlyAskedQuestionList;
  listFrequentlyAskedQuestionsWithDetails: FrequentlyAskedQuestionWithDetailsList;
  listGeneralRecommendations: GeneralRecommendationList;
  listGeneralRecommendationsWithDetails: GeneralRecommendationWithDetailsList;
  listGoalSuggestions: GoalSuggestionList;
  listGoalSuggestionsWithDetails: GoalSuggestionWithDetailsList;
  listSleepAidPosts: SleepAidPostList;
  listSleepAidPostsWithDetails: SleepAidPostWithDetailsList;
  listSleepHabitSuggestions: SleepHabitSuggestionList;
  listSleepHabitSuggestionsWithDetails: SleepHabitSuggestionWithDetailsList;
  listTreatmentsWithDetails: TreatmentWithDetailsList;
};


export type ContentQueryGroupGetTreatmentWeekWithDetailsArgs = {
  treatmentWeekId?: InputMaybe<Scalars['TreatmentWeekId']>;
};


export type ContentQueryGroupListBasicEducationCategoriesArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListBasicEducationCategoriesWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListBasicEducationPostsByCategoryIdArgs = {
  basicEducationCategoryId: Scalars['BasicEducationCategoryId'];
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListBasicEducationPostsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListFrequentlyAskedQuestionsArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListFrequentlyAskedQuestionsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListGeneralRecommendationsArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListGeneralRecommendationsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListGoalSuggestionsArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListGoalSuggestionsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListSleepAidPostsArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListSleepAidPostsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListSleepHabitSuggestionsArgs = {
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
};


export type ContentQueryGroupListSleepHabitSuggestionsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};


export type ContentQueryGroupListTreatmentsWithDetailsArgs = {
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
};

export type ContentSortDefinitionInput = {
  contentSortField: ContentSortField;
  sortDirection: SortDirection;
};

export enum ContentSortField {
  CreatedAt = 'CREATED_AT',
  Priority = 'PRIORITY'
}

export type CreateBasicEducationCategoryInput = {
  englishBackgroundImageUrl: Scalars['String'];
  englishName: Scalars['String'];
  icelandicBackgroundImageUrl: Scalars['String'];
  icelandicName: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateBasicEducationPostInput = {
  basicEducationCategoryId: Scalars['BasicEducationCategoryId'];
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateFrequentlyAskedQuestionInput = {
  englishAnswer: Scalars['String'];
  englishQuestion: Scalars['String'];
  icelandicAnswer: Scalars['String'];
  icelandicQuestion: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateGeneralRecommendationInput = {
  englishAdvice: Scalars['String'];
  englishHint: Scalars['String'];
  icelandicAdvice: Scalars['String'];
  icelandicHint: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateGoalInput = {
  content: Scalars['String'];
  ownerId: Scalars['IdentityId'];
};

export type CreateGoalSuggestionInput = {
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateMorningDiaryInput = {
  dateOfSleep: Scalars['LocalDate'];
  didWakeUpEarlier: Scalars['Boolean'];
  estimatedNumberOfWakeUps: Scalars['Int'];
  feeling?: InputMaybe<Feeling>;
  finalAwakeningAt: Scalars['LocalTime'];
  gotIntoBedAt: Scalars['LocalTime'];
  gotOutOfBedAt: Scalars['LocalTime'];
  howMuchEarlierDidWakeUpDuration: Scalars['Duration'];
  ownerId: Scalars['IdentityId'];
  sleepQuality?: InputMaybe<SleepQuality>;
  totalAwakeningsDuration: Scalars['Duration'];
  triedToGoSleepAt: Scalars['LocalTime'];
  tryingToFallAsleepDuration: Scalars['Duration'];
};

export type CreateNightDiaryInput = {
  alcoholDrinksCount: Scalars['Int'];
  caffeinatedDrinksCount: Scalars['Int'];
  comments?: InputMaybe<Scalars['String']>;
  dateOfSleep: Scalars['LocalDate'];
  didTakeSleepHelpingMedication: Scalars['Boolean'];
  exerciseDuration: Scalars['Duration'];
  fatigueLevel: Scalars['Int'];
  isWorkday: Scalars['Boolean'];
  lastAlcoholDrinkAt?: InputMaybe<Scalars['LocalTime']>;
  lastCaffeinatedDrinkAt?: InputMaybe<Scalars['LocalTime']>;
  medicationName?: InputMaybe<Scalars['String']>;
  moodLevel: Scalars['Int'];
  napAndDozeTotalDuration: Scalars['Duration'];
  napOrDozeCount: Scalars['Int'];
  ownerId: Scalars['IdentityId'];
  sleepinessLevel: Scalars['Int'];
  stoppedUsingScreenAt: Scalars['LocalTime'];
  stressLevel: Scalars['Int'];
};

export type CreateSleepAidPostInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  englishVideoDuration: Scalars['Duration'];
  englishVideoThumbnailUrl: Scalars['String'];
  englishVideoUrl: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  icelandicVideoDuration: Scalars['Duration'];
  icelandicVideoThumbnailUrl: Scalars['String'];
  icelandicVideoUrl: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateSleepHabitSuggestionInput = {
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateTreatmentInput = {
  englishDescription: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicDescription: Scalars['String'];
  icelandicTitle: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

/** Daily morning and night entry */
export type DailyEntry = {
  __typename?: 'DailyEntry';
  /** Date of sleep */
  dateOfSleep: Scalars['LocalDate'];
  /** Morning diary entry */
  morningDiaryEntry?: Maybe<MorningDiary>;
  /** Night diary entry */
  nightDiaryEntry?: Maybe<NightDiary>;
};

export type DeleteBasicEducationCategoryInput = {
  id: Scalars['BasicEducationCategoryId'];
};

export type DeleteBasicEducationPostInput = {
  id: Scalars['BasicEducationPostId'];
};

export type DeleteFrequentlyAskedQuestionInput = {
  id: Scalars['FrequentlyAskedQuestionId'];
};

export type DeleteGeneralRecommendationInput = {
  id: Scalars['GeneralRecommendationId'];
};

export type DeleteGoalInput = {
  id: Scalars['GoalId'];
  ownerId: Scalars['IdentityId'];
};

export type DeleteGoalSuggestionInput = {
  id: Scalars['GoalSuggestionId'];
};

export type DeleteIdentityInput = {
  identityId: Scalars['IdentityId'];
};

export type DeleteMorningDiaryInput = {
  id: Scalars['DiaryId'];
  ownerId: Scalars['IdentityId'];
};

export type DeleteNightDiaryInput = {
  id: Scalars['DiaryId'];
  ownerId: Scalars['IdentityId'];
};

export type DeletePushNotificationTokenInput = {
  identityId: Scalars['IdentityId'];
  token: Scalars['String'];
};

export type DeleteSleepAidPostInput = {
  id: Scalars['SleepAidPostId'];
};

export type DeleteSleepHabitSuggestionInput = {
  id: Scalars['SleepHabitSuggestionId'];
};

export type DeleteTreatmentInput = {
  id: Scalars['TreatmentId'];
};

export type DeleteTreatmentWeekInput = {
  weekId: Scalars['TreatmentWeekId'];
};

export type DeleteTreatmentWeekSleepAdviceInput = {
  sleepAdviceId: Scalars['TreatmentWeekId'];
};

export type DeleteTreatmentWeekVideoInput = {
  videoId: Scalars['TreatmentWeekId'];
};

/** Detailed statistics */
export type DetailedStatistics = {
  __typename?: 'DetailedStatistics';
  /** Statistics for selected period */
  statistics: DiariesStatistics;
  /** Diaries summary */
  summary: DiariesSummary;
};

/** Diaries statistics for selected period */
export type DiariesStatistics = {
  __typename?: 'DiariesStatistics';
  /** Average sleep duration in period */
  averageSleepDuration?: Maybe<Scalars['Duration']>;
  /** Average sleep efficiency */
  averageSleepEfficiency?: Maybe<Scalars['Int']>;
  /** Chart data */
  chartData?: Maybe<Array<Maybe<ChartData>>>;
  /** Selected period */
  period: Period;
  /** Sleep latency duration in period */
  sleepLatencyDuration?: Maybe<Scalars['Duration']>;
  /** Waso duration in period */
  wasoDuration?: Maybe<Scalars['Duration']>;
};

/** Diaries summary */
export type DiariesSummary = {
  __typename?: 'DiariesSummary';
  /** Average sleep duration */
  averageSleepDuration?: Maybe<Scalars['Duration']>;
  /** Current streak */
  currentStreak: Scalars['Int'];
  /** Sleep efficiency */
  sleepEfficiency?: Maybe<Scalars['Int']>;
  /** Total entries */
  totalEntries: Scalars['Int'];
};

export type DiaryMutationGroup = {
  __typename?: 'DiaryMutationGroup';
  createMorningEntry: MorningDiary;
  createNightEntry: NightDiary;
  deleteMorningEntry: MutationResponse;
  deleteNightEntry: MutationResponse;
  updateMorningEntry: MorningDiary;
  updateNightEntry: NightDiary;
};


export type DiaryMutationGroupCreateMorningEntryArgs = {
  input: CreateMorningDiaryInput;
};


export type DiaryMutationGroupCreateNightEntryArgs = {
  input: CreateNightDiaryInput;
};


export type DiaryMutationGroupDeleteMorningEntryArgs = {
  input: DeleteMorningDiaryInput;
};


export type DiaryMutationGroupDeleteNightEntryArgs = {
  input: DeleteNightDiaryInput;
};


export type DiaryMutationGroupUpdateMorningEntryArgs = {
  input: UpdateMorningDiaryInput;
};


export type DiaryMutationGroupUpdateNightEntryArgs = {
  input: UpdateNightDiaryInput;
};

export type DiaryQueryGroup = {
  __typename?: 'DiaryQueryGroup';
  getDetailedStatistics: DetailedStatistics;
  getMonthProgress: MonthProgress;
  getMorningEntry: MorningDiary;
  getNightEntry: NightDiary;
  getReportData: ReportData;
  getWeekProgress: WeekProgress;
  statisticsBetweenTwoWeeks: StatisticsBetweenTwoWeeks;
};


export type DiaryQueryGroupGetDetailedStatisticsArgs = {
  identityId: Scalars['IdentityId'];
  period?: InputMaybe<Period>;
  referenceDate: Scalars['LocalDate'];
};


export type DiaryQueryGroupGetMonthProgressArgs = {
  identityId: Scalars['IdentityId'];
  referenceDate: Scalars['LocalDate'];
};


export type DiaryQueryGroupGetMorningEntryArgs = {
  dateOfSleep: Scalars['LocalDate'];
  identityId: Scalars['IdentityId'];
};


export type DiaryQueryGroupGetNightEntryArgs = {
  dateOfSleep: Scalars['LocalDate'];
  identityId: Scalars['IdentityId'];
};


export type DiaryQueryGroupGetReportDataArgs = {
  identityId: Scalars['IdentityId'];
  referenceDate: Scalars['LocalDate'];
};


export type DiaryQueryGroupGetWeekProgressArgs = {
  identityId: Scalars['IdentityId'];
  referenceDate: Scalars['LocalDate'];
};


export type DiaryQueryGroupStatisticsBetweenTwoWeeksArgs = {
  firstWeekStartDate: Scalars['LocalDate'];
  identityId: Scalars['IdentityId'];
  secondWeekStartDate: Scalars['LocalDate'];
};

export enum ExternalIdentityProvider {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export enum Feeling {
  NotAtAllRested = 'NOT_AT_ALL_RESTED',
  SlightlyRested = 'SLIGHTLY_RESTED',
  SomewhatRested = 'SOMEWHAT_RESTED',
  VeryWellRested = 'VERY_WELL_RESTED',
  WellRested = 'WELL_RESTED'
}

/** Url to upload file to the bucket */
export type FileUploadUrl = {
  __typename?: 'FileUploadUrl';
  /** Timestamp the url expires at */
  expiresAt: Scalars['Instant'];
  /** Presigned url */
  url: Scalars['String'];
};

/** Frequently asked question */
export type FrequentlyAskedQuestion = {
  __typename?: 'FrequentlyAskedQuestion';
  /** Answer */
  answer: Scalars['String'];
  /** Frequently asked question unique identifier */
  id: Scalars['FrequentlyAskedQuestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
  /** Question */
  question: Scalars['String'];
};

/** Frequently asked question details */
export type FrequentlyAskedQuestionDetails = {
  __typename?: 'FrequentlyAskedQuestionDetails';
  /** Answer */
  answer: Scalars['String'];
  /** Language */
  language: Language;
  /** Question */
  question: Scalars['String'];
};

/** List of frequently asked questions */
export type FrequentlyAskedQuestionList = {
  __typename?: 'FrequentlyAskedQuestionList';
  items: Array<FrequentlyAskedQuestion>;
  meta: ListMetadata;
};

/** Frequently asked question with details */
export type FrequentlyAskedQuestionWithDetails = {
  __typename?: 'FrequentlyAskedQuestionWithDetails';
  /** Frequently asked question details */
  details: Array<Maybe<FrequentlyAskedQuestionDetails>>;
  /** Frequently asked question unique identifier */
  id: Scalars['FrequentlyAskedQuestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of frequently asked questions with details */
export type FrequentlyAskedQuestionWithDetailsList = {
  __typename?: 'FrequentlyAskedQuestionWithDetailsList';
  items: Array<FrequentlyAskedQuestionWithDetails>;
  meta: ListMetadata;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

/** General recommendation */
export type GeneralRecommendation = {
  __typename?: 'GeneralRecommendation';
  /** Advice */
  advice: Scalars['String'];
  /** Hint */
  hint: Scalars['String'];
  /** General recommendation unique identifier */
  id: Scalars['GeneralRecommendationId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** General recommendation details */
export type GeneralRecommendationDetails = {
  __typename?: 'GeneralRecommendationDetails';
  /** Advice */
  advice: Scalars['String'];
  /** Hint */
  hint: Scalars['String'];
  /** Language */
  language: Language;
};

/** List of general recommendations */
export type GeneralRecommendationList = {
  __typename?: 'GeneralRecommendationList';
  items: Array<GeneralRecommendation>;
  meta: ListMetadata;
};

/** General recommendation with details */
export type GeneralRecommendationWithDetails = {
  __typename?: 'GeneralRecommendationWithDetails';
  /** General recommendation details */
  details: Array<Maybe<GeneralRecommendationDetails>>;
  /** General recommendation identifier */
  id: Scalars['GeneralRecommendationId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of general recommendation with details */
export type GeneralRecommendationWithDetailsList = {
  __typename?: 'GeneralRecommendationWithDetailsList';
  items: Array<GeneralRecommendationWithDetails>;
  meta: ListMetadata;
};

/** Goal details */
export type Goal = {
  __typename?: 'Goal';
  /** Goal content */
  content: Scalars['String'];
  /** Timestamp the goal was created */
  createdAt: Scalars['Instant'];
  /** Goal unique identifier */
  id: Scalars['GoalId'];
  /** Owner unique identifier */
  ownerId: Scalars['IdentityId'];
  /** Goal status */
  status: Status;
};

/** List of goals */
export type GoalList = {
  __typename?: 'GoalList';
  items: Array<Goal>;
  meta: ListMetadata;
};

export type GoalListFiltersInput = {
  status?: InputMaybe<Status>;
};

export type GoalMutationGroup = {
  __typename?: 'GoalMutationGroup';
  create: Goal;
  delete: MutationResponse;
  setStatus: Goal;
};


export type GoalMutationGroupCreateArgs = {
  input: CreateGoalInput;
};


export type GoalMutationGroupDeleteArgs = {
  input: DeleteGoalInput;
};


export type GoalMutationGroupSetStatusArgs = {
  input: SetGoalStatusInput;
};

export type GoalQueryGroup = {
  __typename?: 'GoalQueryGroup';
  list: GoalList;
};


export type GoalQueryGroupListArgs = {
  filters?: InputMaybe<GoalListFiltersInput>;
  language?: InputMaybe<Language>;
  meta?: InputMaybe<ListMetadataInput>;
  ownerId: Scalars['IdentityId'];
};

/** Goal suggestion */
export type GoalSuggestion = {
  __typename?: 'GoalSuggestion';
  /** Suggestion content */
  content: Scalars['String'];
  /** Goal suggestion unique identifier */
  id: Scalars['GoalSuggestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** Goal suggestion details */
export type GoalSuggestionDetails = {
  __typename?: 'GoalSuggestionDetails';
  /** Suggestion content */
  content: Scalars['String'];
  /** Language */
  language: Language;
};

/** List of goal suggestions */
export type GoalSuggestionList = {
  __typename?: 'GoalSuggestionList';
  items: Array<GoalSuggestion>;
  meta: ListMetadata;
};

/** Goal suggestion with details */
export type GoalSuggestionWithDetails = {
  __typename?: 'GoalSuggestionWithDetails';
  /** Goal suggestion details */
  details: Array<Maybe<GoalSuggestionDetails>>;
  /** Goal suggestion unique identifier */
  id: Scalars['GoalSuggestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of goal suggestions with details */
export type GoalSuggestionWithDetailsList = {
  __typename?: 'GoalSuggestionWithDetailsList';
  items: Array<GoalSuggestionWithDetails>;
  meta: ListMetadata;
};

/** Identity details */
export type Identity = {
  __typename?: 'Identity';
  /** Email address */
  email: Scalars['String'];
  /** Identity unique identifier */
  id: Scalars['IdentityId'];
  /** Is banned */
  isBanned: Scalars['Boolean'];
  /** Is email confirmed */
  isEmailConfirmed: Scalars['Boolean'];
  /** Full name */
  name?: Maybe<Scalars['String']>;
  /** Profile picture url */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** Registered at */
  registeredAt: Scalars['Instant'];
};

/** Identity list */
export type IdentityList = {
  __typename?: 'IdentityList';
  /** Items */
  items: Array<Identity>;
  /** Meta */
  meta: ListMetadata;
};

export type IdentityListFiltersInput = {
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type IdentityMutationGroup = {
  __typename?: 'IdentityMutationGroup';
  authenticateWithExternalIdentityProvider: AuthMutationResponse;
  ban: MutationResponse;
  changePassword: MutationResponse;
  confirmEmail: MutationResponse;
  delete: MutationResponse;
  login: AuthMutationResponse;
  logout: MutationResponse;
  refreshAccessToken: AuthMutationResponse;
  register: MutationResponse;
  requestPasswordReset: MutationResponse;
  resendConfirmationEmail: MutationResponse;
  resetPassword: MutationResponse;
  unban: MutationResponse;
  updateName: Identity;
  updateProfilePicture: Identity;
};


export type IdentityMutationGroupAuthenticateWithExternalIdentityProviderArgs = {
  input: AuthenticateWithExternalIdentityProviderInput;
};


export type IdentityMutationGroupBanArgs = {
  input: BanInput;
};


export type IdentityMutationGroupChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type IdentityMutationGroupConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type IdentityMutationGroupDeleteArgs = {
  input: DeleteIdentityInput;
};


export type IdentityMutationGroupLoginArgs = {
  input: LoginInput;
};


export type IdentityMutationGroupRefreshAccessTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type IdentityMutationGroupRegisterArgs = {
  input: RegisterInput;
};


export type IdentityMutationGroupRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type IdentityMutationGroupResendConfirmationEmailArgs = {
  input: ResendConfirmationEmailInput;
};


export type IdentityMutationGroupResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type IdentityMutationGroupUnbanArgs = {
  input: UnbanInput;
};


export type IdentityMutationGroupUpdateNameArgs = {
  input: UpdateNameInput;
};


export type IdentityMutationGroupUpdateProfilePictureArgs = {
  input: UpdateProfilePictureInput;
};

export type IdentityQueryGroup = {
  __typename?: 'IdentityQueryGroup';
  getById: Identity;
  getMyDetails: Identity;
  getProfilePictureS3UploadUrl: FileUploadUrl;
  list: IdentityList;
};


export type IdentityQueryGroupGetByIdArgs = {
  id: Scalars['IdentityId'];
};


export type IdentityQueryGroupGetProfilePictureS3UploadUrlArgs = {
  id: Scalars['IdentityId'];
};


export type IdentityQueryGroupListArgs = {
  filters?: InputMaybe<IdentityListFiltersInput>;
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<IdentitySortDefinitionInput>;
};

export type IdentitySortDefinitionInput = {
  identitySortField: IdentitySortField;
  sortDirection: SortDirection;
};

export enum IdentitySortField {
  Email = 'EMAIL',
  Name = 'NAME',
  RegisteredAt = 'REGISTERED_AT'
}

export enum Language {
  English = 'ENGLISH',
  Icelandic = 'ICELANDIC'
}

/** Lifestyle details */
export type Lifestyle = {
  __typename?: 'Lifestyle';
  /** Does user consume alcohol */
  doesConsumeAlcohol: Scalars['Boolean'];
  /** Does user consume caffeine */
  doesConsumeCaffeine: Scalars['Boolean'];
  /** Does user consume nicotine */
  doesConsumeNicotine: Scalars['Boolean'];
  /** Does user exercise */
  doesExercise: Scalars['Boolean'];
  /** Does user take meditation */
  doesTakeMeditation: Scalars['Boolean'];
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  nextPageCursor?: Maybe<Scalars['String']>;
  pageCursor?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
  totalCount: Scalars['Long'];
};

export type ListMetadataInput = {
  pageCursor?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type LoginInput = {
  email: Scalars['String'];
  loginAs?: InputMaybe<Role>;
  password: Scalars['String'];
};

/** Month progress */
export type MonthProgress = {
  __typename?: 'MonthProgress';
  /** Month progress items */
  items: Array<MonthProgressItem>;
};

/** Month progress item */
export type MonthProgressItem = {
  __typename?: 'MonthProgressItem';
  /** Is morning or night diary entry added that day */
  addedMorningOrNightDiaryEntry: Scalars['Boolean'];
  /** Date of sleep */
  dateOfSleep: Scalars['LocalDate'];
};

/** Morning diary details */
export type MorningDiary = {
  __typename?: 'MorningDiary';
  /** Timestamp the diary was created */
  createdAt: Scalars['Instant'];
  /** Date the diary was created for */
  dateOfSleep: Scalars['LocalDate'];
  /** Did user wake up earlier than user planned */
  didWakeUpEarlier: Scalars['Boolean'];
  /** How many times did user wake up, not counting final awakening */
  estimatedNumberOfWakeUps: Scalars['Int'];
  /** How restful or refreshed did user feel when user woke up for the day */
  feeling?: Maybe<Feeling>;
  /** What time was user's final awakening */
  finalAwakeningAt: Scalars['LocalTime'];
  /** Time at user got into bed */
  gotIntoBedAt: Scalars['LocalTime'];
  /** What time did user get out of bed for the day */
  gotOutOfBedAt: Scalars['LocalTime'];
  /** How much earlier did user wake up than planned */
  howMuchEarlierDidWakeUpDuration: Scalars['Duration'];
  /** Morning diary unique identifier */
  id: Scalars['DiaryId'];
  /** Owner unique identifier */
  ownerId: Scalars['IdentityId'];
  /** How would user rate the quality of sleep */
  sleepQuality?: Maybe<SleepQuality>;
  statistics: MorningDiaryStatistics;
  /** In total, how long did these awakenings last */
  totalAwakeningsDuration: Scalars['Duration'];
  /** Time at user tried to go sleep */
  triedToGoSleepAt: Scalars['LocalTime'];
  /** How long did it take user to fall asleep */
  tryingToFallAsleepDuration: Scalars['Duration'];
};

/** Morning diary statistics */
export type MorningDiaryStatistics = {
  __typename?: 'MorningDiaryStatistics';
  /** Sleep efficiency */
  sleepEfficiency?: Maybe<Scalars['Int']>;
  /** Total time asleep */
  totalTimeAsleepDuration: Scalars['Duration'];
  /** Total time in bed */
  totalTimeInBedDuration: Scalars['Duration'];
};

export type Mutation = {
  __typename?: 'Mutation';
  content: ContentMutationGroup;
  diary: DiaryMutationGroup;
  goal: GoalMutationGroup;
  identity: IdentityMutationGroup;
  treatment: TreatmentMutationGroup;
  user: UserMutationGroup;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  success: Scalars['Boolean'];
};

/** Night diary details */
export type NightDiary = {
  __typename?: 'NightDiary';
  /** How many drinks containing alcohol did user have */
  alcoholDrinksCount: Scalars['Int'];
  /** How many caffeinated drinks did user have */
  caffeinatedDrinksCount: Scalars['Int'];
  /** User's comments about sleep */
  comments?: Maybe<Scalars['String']>;
  /** Timestamp the diary was created */
  createdAt: Scalars['Instant'];
  /** Date the diary was created for */
  dateOfSleep: Scalars['LocalDate'];
  /** Did user take any over-the-counter or prescription medication(s) to help sleep */
  didTakeSleepHelpingMedication: Scalars['Boolean'];
  /** Was much did user exercise */
  exerciseDuration: Scalars['Duration'];
  /** How fatigue was user today */
  fatigueLevel: Scalars['Int'];
  /** Morning diary unique identifier */
  id: Scalars['DiaryId'];
  /** Was today a workday or freeday */
  isWorkday: Scalars['Boolean'];
  /** What time was user's last alcohol drink */
  lastAlcoholDrinkAt?: Maybe<Scalars['LocalTime']>;
  /** What time was user's last caffeinated drink */
  lastCaffeinatedDrinkAt?: Maybe<Scalars['LocalTime']>;
  /** Name of the medication that user toke */
  medicationName?: Maybe<Scalars['String']>;
  /** How was user's mood today */
  moodLevel: Scalars['Int'];
  /** In total, how long did user nap or doze */
  napAndDozeTotalDuration: Scalars['Duration'];
  /** How many times did user nap or doze */
  napOrDozeCount: Scalars['Int'];
  /** Owner unique identifier */
  ownerId: Scalars['IdentityId'];
  /** How sleepy was user today */
  sleepinessLevel: Scalars['Int'];
  /** What time user stopped using screen */
  stoppedUsingScreenAt: Scalars['LocalTime'];
  /** How stressed was user today */
  stressLevel: Scalars['Int'];
};

export type OnboardInput = {
  averageCycleDuration?: InputMaybe<Scalars['Duration']>;
  birthDate: Scalars['LocalDate'];
  children?: InputMaybe<Array<InputMaybe<ChildInput>>>;
  dayShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  dayShiftTo?: InputMaybe<Scalars['LocalTime']>;
  doLiveTogether?: InputMaybe<Scalars['Boolean']>;
  doesConsumeAlcohol: Scalars['Boolean'];
  doesConsumeCaffeine: Scalars['Boolean'];
  doesConsumeNicotine: Scalars['Boolean'];
  doesExercise: Scalars['Boolean'];
  doesTakeMeditation: Scalars['Boolean'];
  eveningShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  eveningShiftTo?: InputMaybe<Scalars['LocalTime']>;
  firstDayOfLastPeriod?: InputMaybe<Scalars['LocalDate']>;
  gender: Gender;
  goalsContents?: InputMaybe<Array<Scalars['String']>>;
  identityId: Scalars['IdentityId'];
  isPeriodRegular?: InputMaybe<Scalars['Boolean']>;
  lastPeriodDuration?: InputMaybe<Scalars['Duration']>;
  nightShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  nightShiftTo?: InputMaybe<Scalars['LocalTime']>;
  scheduleType: ScheduleType;
  sleepBreakFrom?: InputMaybe<Scalars['LocalTime']>;
  sleepBreakTo?: InputMaybe<Scalars['LocalTime']>;
  sleepHabits: Array<Scalars['String']>;
  stillGetPeriod?: InputMaybe<Scalars['Boolean']>;
  wakeUpAt: Scalars['LocalTime'];
};

export enum Period {
  CurrentMonth = 'CURRENT_MONTH',
  CurrentWeek = 'CURRENT_WEEK',
  LastHalfYear = 'LAST_HALF_YEAR',
  LastSevenDays = 'LAST_SEVEN_DAYS',
  LastTwoWeeks = 'LAST_TWO_WEEKS',
  None = 'NONE'
}

/** User's personal info */
export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  /** Average duration of cycle */
  averageCycleDuration?: Maybe<Scalars['Duration']>;
  /** User's date of birth */
  birthDate: Scalars['LocalDate'];
  /** Children */
  children?: Maybe<Array<Maybe<Child>>>;
  /** Does user live with children */
  doLiveTogether?: Maybe<Scalars['Boolean']>;
  /** What was first day of last period */
  firstDayOfLastPeriod?: Maybe<Scalars['LocalDate']>;
  /** User's gender */
  gender: Gender;
  /** Is period regular */
  isPeriodRegular?: Maybe<Scalars['Boolean']>;
  /** What was the duration of last period */
  lastPeriodDuration?: Maybe<Scalars['Duration']>;
  /** Does user still get period */
  stillGetPeriod?: Maybe<Scalars['Boolean']>;
};

/** Personal recommendation */
export type PersonalRecommendationType = {
  __typename?: 'PersonalRecommendationType';
  /** Advice */
  advice: Scalars['String'];
  /** Hint */
  hint: Scalars['String'];
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Query = {
  __typename?: 'Query';
  content: ContentQueryGroup;
  diary: DiaryQueryGroup;
  goal: GoalQueryGroup;
  identity: IdentityQueryGroup;
  treatment: TreatmentQueryGroup;
  user: UserQueryGroup;
};

export type RegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

/** Report data */
export type ReportData = {
  __typename?: 'ReportData';
  /** Daily entries */
  dailyEntries: Array<DailyEntry>;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type ResendConfirmationEmailInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum ScheduleType {
  CurrentlyNotWorking = 'CURRENTLY_NOT_WORKING',
  Daytime = 'DAYTIME',
  DayOrNightShifts = 'DAY_OR_NIGHT_SHIFTS',
  DayShifts = 'DAY_SHIFTS',
  Evenings = 'EVENINGS'
}

export type SetBasicEducationCategoryPriorityInputType = {
  id: Scalars['BasicEducationCategoryId'];
  priority: Scalars['Int'];
};

export type SetBasicEducationPostPriorityInputType = {
  id: Scalars['BasicEducationPostId'];
  priority: Scalars['Int'];
};

export type SetFrequentlyAskedQuestionPriorityInputType = {
  id: Scalars['FrequentlyAskedQuestionId'];
  priority: Scalars['Int'];
};

export type SetGeneralRecommendationPriorityInputType = {
  id: Scalars['GeneralRecommendationId'];
  priority: Scalars['Int'];
};

export type SetGoalStatusInput = {
  id: Scalars['GoalId'];
  ownerId: Scalars['IdentityId'];
  status: Status;
};

export type SetGoalSuggestionPriorityInputType = {
  id: Scalars['GoalSuggestionId'];
  priority: Scalars['Int'];
};

export type SetGoalsInput = {
  goalsContents: Array<Scalars['String']>;
  identityId: Scalars['IdentityId'];
};

export type SetLifestyleInput = {
  doesConsumeAlcohol: Scalars['Boolean'];
  doesConsumeCaffeine: Scalars['Boolean'];
  doesConsumeNicotine: Scalars['Boolean'];
  doesExercise: Scalars['Boolean'];
  doesTakeMeditation: Scalars['Boolean'];
  identityId: Scalars['IdentityId'];
};

export type SetPersonalInfoInput = {
  averageCycleDuration?: InputMaybe<Scalars['Duration']>;
  birthDate: Scalars['LocalDate'];
  children?: InputMaybe<Array<InputMaybe<ChildInput>>>;
  doLiveTogether?: InputMaybe<Scalars['Boolean']>;
  firstDayOfLastPeriod?: InputMaybe<Scalars['LocalDate']>;
  gender: Gender;
  identityId: Scalars['IdentityId'];
  isPeriodRegular?: InputMaybe<Scalars['Boolean']>;
  lastPeriodDuration?: InputMaybe<Scalars['Duration']>;
  stillGetPeriod?: InputMaybe<Scalars['Boolean']>;
};

export type SetSleepAidPostPriorityInputType = {
  id: Scalars['SleepAidPostId'];
  priority: Scalars['Int'];
};

export type SetSleepHabitSuggestionPriorityInputType = {
  id: Scalars['SleepHabitSuggestionId'];
  priority: Scalars['Int'];
};

export type SetSleepRoutineInput = {
  identityId: Scalars['IdentityId'];
  sleepHabits: Array<Scalars['String']>;
  wakeUpAt: Scalars['LocalTime'];
};

export type SetTreatmentPriorityInput = {
  id: Scalars['TreatmentId'];
  priority: Scalars['Int'];
};

export type SetWorkScheduleInput = {
  dayShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  dayShiftTo?: InputMaybe<Scalars['LocalTime']>;
  eveningShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  eveningShiftTo?: InputMaybe<Scalars['LocalTime']>;
  identityId: Scalars['IdentityId'];
  nightShiftFrom?: InputMaybe<Scalars['LocalTime']>;
  nightShiftTo?: InputMaybe<Scalars['LocalTime']>;
  scheduleType: ScheduleType;
  sleepBreakFrom?: InputMaybe<Scalars['LocalTime']>;
  sleepBreakTo?: InputMaybe<Scalars['LocalTime']>;
};

/** Sleep aid post */
export type SleepAidPost = {
  __typename?: 'SleepAidPost';
  /** Post content */
  content: Scalars['String'];
  /** Sleep aid post unique identifier */
  id: Scalars['SleepAidPostId'];
  /** priority for sorting */
  priority: Scalars['Int'];
  /** Post title */
  title: Scalars['String'];
  /** Post video duration */
  videoDuration: Scalars['Duration'];
  /** Post video thumbnail url */
  videoThumbnailUrl: Scalars['String'];
  /** Post video url */
  videoUrl: Scalars['String'];
};

/** Sleep aid post details */
export type SleepAidPostDetails = {
  __typename?: 'SleepAidPostDetails';
  /** Post content */
  content: Scalars['String'];
  /** Language */
  language: Language;
  /** Post title */
  title: Scalars['String'];
  /** Post video duration */
  videoDuration: Scalars['Duration'];
  /** Post video thumbnail url */
  videoThumbnailUrl: Scalars['String'];
  /** Post video url */
  videoUrl: Scalars['String'];
};

/** List of sleep aid posts */
export type SleepAidPostList = {
  __typename?: 'SleepAidPostList';
  items: Array<SleepAidPost>;
  meta: ListMetadata;
};

/** List of sleep aid posts with details */
export type SleepAidPostWithDetailsList = {
  __typename?: 'SleepAidPostWithDetailsList';
  items: Array<SleepAidPostWithDetailsWithDetails>;
  meta: ListMetadata;
};

/** Sleep aid post with details */
export type SleepAidPostWithDetailsWithDetails = {
  __typename?: 'SleepAidPostWithDetailsWithDetails';
  /** Sleep aid post details */
  details: Array<Maybe<SleepAidPostDetails>>;
  /** Sleep aid post unique identifier */
  id: Scalars['SleepAidPostId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

export type SleepDurationComparison = {
  __typename?: 'SleepDurationComparison';
  firstWeekSleepDuration: Scalars['Duration'];
  percentageDifference?: Maybe<Scalars['Int']>;
  secondWeekSleepDuration: Scalars['Duration'];
};

export type SleepEfficiencyComparison = {
  __typename?: 'SleepEfficiencyComparison';
  firstWeekSleepEfficiency: Scalars['Int'];
  percentageDifference?: Maybe<Scalars['Int']>;
  secondWeekSleepEfficiency: Scalars['Int'];
};

/** Sleep habit details */
export type SleepHabit = {
  __typename?: 'SleepHabit';
  /** Sleep habit content */
  content: Scalars['String'];
};

/** Sleep habit suggestion */
export type SleepHabitSuggestion = {
  __typename?: 'SleepHabitSuggestion';
  /** Suggestion content */
  content: Scalars['String'];
  /** Sleep habit suggestion unique identifier */
  id: Scalars['SleepHabitSuggestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** Sleep habit suggestion details */
export type SleepHabitSuggestionDetails = {
  __typename?: 'SleepHabitSuggestionDetails';
  /** Suggestion content */
  content: Scalars['String'];
  /** Language */
  language: Language;
};

/** List of sleep habit suggestions */
export type SleepHabitSuggestionList = {
  __typename?: 'SleepHabitSuggestionList';
  items: Array<SleepHabitSuggestion>;
  meta: ListMetadata;
};

/** Sleep habit suggestion with details */
export type SleepHabitSuggestionWithDetails = {
  __typename?: 'SleepHabitSuggestionWithDetails';
  /** Sleep habit suggestion details */
  details: Array<Maybe<SleepHabitSuggestionDetails>>;
  /** Sleep habit suggestion unique identifier */
  id: Scalars['SleepHabitSuggestionId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** List of sleep habit suggestion with details */
export type SleepHabitSuggestionWithDetailsList = {
  __typename?: 'SleepHabitSuggestionWithDetailsList';
  items: Array<SleepHabitSuggestionWithDetails>;
  meta: ListMetadata;
};

export type SleepLatencyComparison = {
  __typename?: 'SleepLatencyComparison';
  firstWeekSleepLatencyDuration: Scalars['Duration'];
  percentageDifference?: Maybe<Scalars['Int']>;
  secondWeekSleepLatencyDuration: Scalars['Duration'];
};

export enum SleepQuality {
  Fair = 'FAIR',
  Good = 'GOOD',
  Poor = 'POOR',
  VeryGood = 'VERY_GOOD',
  VeryPoor = 'VERY_POOR'
}

/** Sleep routine details */
export type SleepRoutine = {
  __typename?: 'SleepRoutine';
  /** Sleep habits */
  sleepHabits: Array<Maybe<SleepHabit>>;
  /** Time at user wakes up */
  wakeUpAt: Scalars['LocalTime'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Statistics comparison between two weeks */
export type StatisticsBetweenTwoWeeks = {
  __typename?: 'StatisticsBetweenTwoWeeks';
  sleepDurationComparison: SleepDurationComparison;
  sleepEfficiencyComparison: SleepEfficiencyComparison;
  sleepLatencyComparison: SleepLatencyComparison;
  wasoComparison: WasoComparison;
};

export enum Status {
  Achieved = 'ACHIEVED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** Time period */
export type TimePeriod = {
  __typename?: 'TimePeriod';
  /** From time */
  from: Scalars['LocalTime'];
  /** To time */
  to: Scalars['LocalTime'];
};

/** Treatment with details and user's status */
export type Treatment = {
  __typename?: 'Treatment';
  description: Scalars['String'];
  /** treatment unique identifier */
  id: Scalars['TreatmentId'];
  /** priority for sorting */
  priority: Scalars['Int'];
  status: Status;
  title: Scalars['String'];
  weeks: Array<Maybe<TreatmentWeek>>;
};

export type TreatmentDetails = {
  __typename?: 'TreatmentDetails';
  description: Scalars['String'];
  /** Language */
  language: Language;
  title: Scalars['String'];
};

export type TreatmentGoal = {
  __typename?: 'TreatmentGoal';
  content: Scalars['String'];
  id: Scalars['GoalId'];
  isCompleted: Scalars['Boolean'];
  priority: Scalars['Int'];
};

export type TreatmentMutationGroup = {
  __typename?: 'TreatmentMutationGroup';
  startTreatment: MutationResponse;
  treatmentWeek: TreatmentWeekMutationGroup;
};


export type TreatmentMutationGroupStartTreatmentArgs = {
  treatmentId: Scalars['TreatmentId'];
  userId: Scalars['IdentityId'];
};

export type TreatmentQueryGroup = {
  __typename?: 'TreatmentQueryGroup';
  list: Array<Maybe<Treatment>>;
  treatmentWeek: TreatmentWeekWithDetails;
};


export type TreatmentQueryGroupListArgs = {
  language?: InputMaybe<Language>;
  userId: Scalars['IdentityId'];
};


export type TreatmentQueryGroupTreatmentWeekArgs = {
  language?: InputMaybe<Language>;
  userId: Scalars['IdentityId'];
  weekId: Scalars['TreatmentWeekId'];
};

export type TreatmentSleepAdvice = {
  __typename?: 'TreatmentSleepAdvice';
  content: Scalars['String'];
  /** treatment sleep advice unique identifier */
  id: Scalars['TreatmentSleepAdviceId'];
  isRead: Scalars['Boolean'];
  priority: Scalars['Int'];
  title: Scalars['String'];
};

export type TreatmentSleepAdviceDetails = {
  __typename?: 'TreatmentSleepAdviceDetails';
  content: Scalars['String'];
  /** Language */
  language: Language;
  title: Scalars['String'];
};

export type TreatmentSleepAdviceWithDetails = {
  __typename?: 'TreatmentSleepAdviceWithDetails';
  /** treatment sleep advice details */
  details: Array<Maybe<TreatmentSleepAdviceDetails>>;
  /** treatment sleep advice unique identifier */
  id: Scalars['TreatmentSleepAdviceId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

export type TreatmentVideo = {
  __typename?: 'TreatmentVideo';
  content: Scalars['String'];
  /** treatment video unique identifier */
  id: Scalars['TreatmentVideoId'];
  isWatched: Scalars['Boolean'];
  priority: Scalars['Int'];
  title: Scalars['String'];
  videoDuration: Scalars['Duration'];
  videoThumbnailUrl: Scalars['String'];
  videoUrl: Scalars['String'];
};

export type TreatmentVideoDetails = {
  __typename?: 'TreatmentVideoDetails';
  /** Post content */
  content: Scalars['String'];
  /** Language */
  language: Language;
  /** Post title */
  title: Scalars['String'];
  /** Post video duration */
  videoDuration: Scalars['Duration'];
  /** Post video thumbnail url */
  videoThumbnailUrl: Scalars['String'];
  /** Post video url */
  videoUrl: Scalars['String'];
};

export type TreatmentVideoWithDetails = {
  __typename?: 'TreatmentVideoWithDetails';
  /** treatment video details */
  details: Array<Maybe<TreatmentVideoDetails>>;
  /** treatment video unique identifier */
  id: Scalars['TreatmentVideoId'];
  /** priority for sorting */
  priority: Scalars['Int'];
};

/** Treatment week with details and user's status */
export type TreatmentWeek = {
  __typename?: 'TreatmentWeek';
  dateFrom?: Maybe<Scalars['LocalDate']>;
  dateTo?: Maybe<Scalars['LocalDate']>;
  /** treatment unique identifier */
  id: Scalars['TreatmentWeekId'];
  /** treatment unique identifier */
  number: Scalars['Int'];
  status: Status;
};

/** Treatment week */
export type TreatmentWeekContent = {
  __typename?: 'TreatmentWeekContent';
  /** treatment week unique identifier */
  id: Scalars['TreatmentWeekId'];
  /** treatment week number */
  number: Scalars['Int'];
};

export type TreatmentWeekMutationGroup = {
  __typename?: 'TreatmentWeekMutationGroup';
  addGoal: TreatmentWeek;
  deleteGoal: TreatmentWeek;
  goalSetIsCompleted: TreatmentWeek;
  sleepAdviceSetIsRead: TreatmentWeek;
  videoSetIsWatched: TreatmentWeek;
};


export type TreatmentWeekMutationGroupAddGoalArgs = {
  content: Scalars['String'];
  priority: Scalars['Int'];
  userId: Scalars['IdentityId'];
  weekId: Scalars['TreatmentWeekId'];
};


export type TreatmentWeekMutationGroupDeleteGoalArgs = {
  goalId: Scalars['GoalId'];
  userId: Scalars['IdentityId'];
};


export type TreatmentWeekMutationGroupGoalSetIsCompletedArgs = {
  goalId: Scalars['GoalId'];
  userId: Scalars['IdentityId'];
  value: Scalars['Boolean'];
};


export type TreatmentWeekMutationGroupSleepAdviceSetIsReadArgs = {
  sleepAdviceId: Scalars['TreatmentSleepAdviceId'];
  userId: Scalars['IdentityId'];
  value: Scalars['Boolean'];
  weekId: Scalars['TreatmentWeekId'];
};


export type TreatmentWeekMutationGroupVideoSetIsWatchedArgs = {
  userId: Scalars['IdentityId'];
  value: Scalars['Boolean'];
  videoId: Scalars['TreatmentVideoId'];
  weekId: Scalars['TreatmentWeekId'];
};

/** Treatment week with details and user's status */
export type TreatmentWeekWithDetails = {
  __typename?: 'TreatmentWeekWithDetails';
  allSleepAdvicesRead: Scalars['Boolean'];
  allSleepEntriesAdded: Scalars['Boolean'];
  allVideosWatched: Scalars['Boolean'];
  avgSleepTime?: Maybe<Scalars['Duration']>;
  bedTime?: Maybe<Scalars['LocalTime']>;
  dateFrom?: Maybe<Scalars['LocalDate']>;
  dateTo?: Maybe<Scalars['LocalDate']>;
  goals?: Maybe<Array<Maybe<TreatmentGoal>>>;
  goalsSet: Scalars['Boolean'];
  /** treatment unique identifier */
  id: Scalars['TreatmentWeekId'];
  /** treatment unique identifier */
  number: Scalars['Int'];
  prescribedSleepDuration?: Maybe<Scalars['Duration']>;
  sleepAdvices?: Maybe<Array<Maybe<TreatmentSleepAdvice>>>;
  sleepEfficiency?: Maybe<Scalars['Int']>;
  sleepEntriesCount: Scalars['Int'];
  status: Status;
  videos?: Maybe<Array<Maybe<TreatmentVideo>>>;
  wakeTime?: Maybe<Scalars['LocalTime']>;
};

export type TreatmentWeekWithDetailsContent = {
  __typename?: 'TreatmentWeekWithDetailsContent';
  /** treatment unique identifier */
  id: Scalars['TreatmentWeekId'];
  /** treatment week number */
  number: Scalars['Int'];
  /** treatment week sleep advices */
  sleepAdvices: Array<Maybe<TreatmentSleepAdviceWithDetails>>;
  /** treatment week videos */
  videos: Array<Maybe<TreatmentVideoWithDetails>>;
};

/** Treatment with details */
export type TreatmentWithDetails = {
  __typename?: 'TreatmentWithDetails';
  /** treatment details */
  details: Array<Maybe<TreatmentDetails>>;
  /** treatment unique identifier */
  id: Scalars['TreatmentId'];
  /** priority for sorting */
  priority: Scalars['Int'];
  /** treatment weeks */
  weeks: Array<Maybe<TreatmentWeekContent>>;
};

/** List of treatments with details */
export type TreatmentWithDetailsList = {
  __typename?: 'TreatmentWithDetailsList';
  items: Array<TreatmentWithDetails>;
  meta: ListMetadata;
};

export type UnbanInput = {
  identityId: Scalars['IdentityId'];
};

export type UpdateBasicEducationCategoryInput = {
  englishBackgroundImageUrl: Scalars['String'];
  englishName: Scalars['String'];
  icelandicBackgroundImageUrl: Scalars['String'];
  icelandicName: Scalars['String'];
  id: Scalars['BasicEducationCategoryId'];
  priority: Scalars['Int'];
};

export type UpdateBasicEducationPostInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  id: Scalars['BasicEducationPostId'];
  priority: Scalars['Int'];
};

export type UpdateBirthDateInput = {
  birthDate: Scalars['LocalDate'];
  identityId: Scalars['IdentityId'];
};

export type UpdateFrequentlyAskedQuestionInput = {
  englishAnswer: Scalars['String'];
  englishQuestion: Scalars['String'];
  icelandicAnswer: Scalars['String'];
  icelandicQuestion: Scalars['String'];
  id: Scalars['FrequentlyAskedQuestionId'];
  priority: Scalars['Int'];
};

export type UpdateGenderInput = {
  gender: Gender;
  identityId: Scalars['IdentityId'];
};

export type UpdateGeneralRecommendationInput = {
  englishAdvice: Scalars['String'];
  englishHint: Scalars['String'];
  icelandicAdvice: Scalars['String'];
  icelandicHint: Scalars['String'];
  id: Scalars['GeneralRecommendationId'];
  priority: Scalars['Int'];
};

export type UpdateGoalSuggestionInput = {
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  id: Scalars['GoalSuggestionId'];
  priority: Scalars['Int'];
};

export type UpdateMorningDiaryInput = {
  didWakeUpEarlier: Scalars['Boolean'];
  estimatedNumberOfWakeUps: Scalars['Int'];
  feeling?: InputMaybe<Feeling>;
  finalAwakeningAt: Scalars['LocalTime'];
  gotIntoBedAt: Scalars['LocalTime'];
  gotOutOfBedAt: Scalars['LocalTime'];
  howMuchEarlierDidWakeUpDuration: Scalars['Duration'];
  id: Scalars['DiaryId'];
  ownerId: Scalars['IdentityId'];
  sleepQuality?: InputMaybe<SleepQuality>;
  totalAwakeningsDuration: Scalars['Duration'];
  triedToGoSleepAt: Scalars['LocalTime'];
  tryingToFallAsleepDuration: Scalars['Duration'];
};

export type UpdateNameInput = {
  identityId: Scalars['IdentityId'];
  name: Scalars['String'];
};

export type UpdateNightDiaryInput = {
  alcoholDrinksCount: Scalars['Int'];
  caffeinatedDrinksCount: Scalars['Int'];
  comments?: InputMaybe<Scalars['String']>;
  didTakeSleepHelpingMedication: Scalars['Boolean'];
  exerciseDuration: Scalars['Duration'];
  fatigueLevel: Scalars['Int'];
  id: Scalars['DiaryId'];
  isWorkday: Scalars['Boolean'];
  lastAlcoholDrinkAt?: InputMaybe<Scalars['LocalTime']>;
  lastCaffeinatedDrinkAt?: InputMaybe<Scalars['LocalTime']>;
  medicationName?: InputMaybe<Scalars['String']>;
  moodLevel: Scalars['Int'];
  napAndDozeTotalDuration: Scalars['Duration'];
  napOrDozeCount: Scalars['Int'];
  ownerId: Scalars['IdentityId'];
  sleepinessLevel: Scalars['Int'];
  stoppedUsingScreenAt: Scalars['LocalTime'];
  stressLevel: Scalars['Int'];
};

export type UpdateProfilePictureInput = {
  identityId: Scalars['IdentityId'];
  profilePictureUrl: Scalars['String'];
};

export type UpdateSleepAidPostInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  englishVideoDuration: Scalars['Duration'];
  englishVideoThumbnailUrl: Scalars['String'];
  englishVideoUrl: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  icelandicVideoDuration: Scalars['Duration'];
  icelandicVideoThumbnailUrl: Scalars['String'];
  icelandicVideoUrl: Scalars['String'];
  id: Scalars['SleepAidPostId'];
  priority: Scalars['Int'];
};

export type UpdateSleepHabitSuggestionInput = {
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  id: Scalars['SleepHabitSuggestionId'];
  priority: Scalars['Int'];
};

export type UpdateTreatmentInput = {
  englishDescription: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicDescription: Scalars['String'];
  icelandicTitle: Scalars['String'];
  id: Scalars['TreatmentId'];
  priority: Scalars['Int'];
};

export type UpdateTreatmentSleepAdviceInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  sleepAdviceId: Scalars['TreatmentSleepAdviceId'];
};

export type UpdateTreatmentVideoInput = {
  englishContent: Scalars['String'];
  englishTitle: Scalars['String'];
  englishVideoDuration: Scalars['Duration'];
  englishVideoThumbnailUrl: Scalars['String'];
  englishVideoUrl: Scalars['String'];
  icelandicContent: Scalars['String'];
  icelandicTitle: Scalars['String'];
  icelandicVideoDuration: Scalars['Duration'];
  icelandicVideoThumbnailUrl: Scalars['String'];
  icelandicVideoUrl: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  videoId: Scalars['TreatmentWeekId'];
};

export type UpdateTreatmentWeekInput = {
  number: Scalars['Int'];
  weekId: Scalars['TreatmentWeekId'];
};

/** User details */
export type User = {
  __typename?: 'User';
  /** Does user set goals during onboarding */
  areGoalsSetDuringOnboarding: Scalars['Boolean'];
  /** Identity unique identifier */
  id: Scalars['IdentityId'];
  /** Lifestyle details */
  lifestyle?: Maybe<Lifestyle>;
  /** Personal information */
  personalInfo?: Maybe<PersonalInfo>;
  /** Sleep routine details */
  sleepRoutine?: Maybe<SleepRoutine>;
  /** Work schedule details */
  workSchedule?: Maybe<WorkSchedule>;
};

export type UserMutationGroup = {
  __typename?: 'UserMutationGroup';
  addPushNotificationToken: MutationResponse;
  deletePushNotificationToken: MutationResponse;
  onboard: User;
  setGoalsDuringOnboarding: User;
  setLifestyle: User;
  setPersonalInfo: User;
  setSleepRoutine: User;
  setWorkSchedule: User;
  updateBirthDate: User;
  updateGender: User;
};


export type UserMutationGroupAddPushNotificationTokenArgs = {
  input: AddPushNotificationTokenInput;
};


export type UserMutationGroupDeletePushNotificationTokenArgs = {
  input: DeletePushNotificationTokenInput;
};


export type UserMutationGroupOnboardArgs = {
  input: OnboardInput;
};


export type UserMutationGroupSetGoalsDuringOnboardingArgs = {
  input: SetGoalsInput;
};


export type UserMutationGroupSetLifestyleArgs = {
  input: SetLifestyleInput;
};


export type UserMutationGroupSetPersonalInfoArgs = {
  input: SetPersonalInfoInput;
};


export type UserMutationGroupSetSleepRoutineArgs = {
  input: SetSleepRoutineInput;
};


export type UserMutationGroupSetWorkScheduleArgs = {
  input: SetWorkScheduleInput;
};


export type UserMutationGroupUpdateBirthDateArgs = {
  input: UpdateBirthDateInput;
};


export type UserMutationGroupUpdateGenderArgs = {
  input: UpdateGenderInput;
};

export type UserQueryGroup = {
  __typename?: 'UserQueryGroup';
  getByIdentityId: User;
  hasPushNotificationToken: Scalars['Boolean'];
  listPersonalRecommendations: Array<Maybe<PersonalRecommendationType>>;
};


export type UserQueryGroupGetByIdentityIdArgs = {
  identityId: Scalars['IdentityId'];
  language?: InputMaybe<Language>;
};


export type UserQueryGroupHasPushNotificationTokenArgs = {
  identityId: Scalars['IdentityId'];
  token: Scalars['String'];
};


export type UserQueryGroupListPersonalRecommendationsArgs = {
  identityId: Scalars['IdentityId'];
  language?: InputMaybe<Language>;
  referenceDate: Scalars['LocalDate'];
};

export type WasoComparison = {
  __typename?: 'WasoComparison';
  firstWeekWasoDuration: Scalars['Duration'];
  percentageDifference?: Maybe<Scalars['Int']>;
  secondWeekWasoDuration: Scalars['Duration'];
};

/** Week progress */
export type WeekProgress = {
  __typename?: 'WeekProgress';
  /** Week progress items */
  items: Array<WeekProgressItem>;
};

/** Week progress item */
export type WeekProgressItem = {
  __typename?: 'WeekProgressItem';
  /** Is morning diary entry added that day */
  addedMorningDiaryEntry: Scalars['Boolean'];
  /** Is night diary entry added that day */
  addedNightDiaryEntry: Scalars['Boolean'];
  /** Date of sleep */
  dateOfSleep: Scalars['LocalDate'];
  /** Total time asleep duration */
  totalTimeAsleepDuration?: Maybe<Scalars['Duration']>;
};

/** Work schedule details */
export type WorkSchedule = {
  __typename?: 'WorkSchedule';
  /** Day shift */
  dayShift?: Maybe<TimePeriod>;
  /** Evening shift */
  eveningShift?: Maybe<TimePeriod>;
  /** Night shift */
  nightShift?: Maybe<TimePeriod>;
  /** Work schedule type */
  scheduleType: ScheduleType;
  /** Sleep break */
  sleepBreak?: Maybe<TimePeriod>;
};

export type MetaFragment = { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any };

export type AddSleepAdviceToTreatmentWeekMutationVariables = Exact<{
  input: AddSleepAdviceToTreatmentWeekInput;
}>;


export type AddSleepAdviceToTreatmentWeekMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', addSleepAdviceToTreatmentWeek: { __typename?: 'MutationResponse', success: boolean } } };

export type AddVideoToTreatmentWeekMutationVariables = Exact<{
  input: AddVideoToTreatmentWeekInput;
}>;


export type AddVideoToTreatmentWeekMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', addVideoToTreatmentWeek: { __typename?: 'MutationResponse', success: boolean } } };

export type AddWeekToTreatmentMutationVariables = Exact<{
  input: AddWeekToTreatmentInput;
}>;


export type AddWeekToTreatmentMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', addWeekToTreatment: { __typename?: 'MutationResponse', success: boolean } } };

export type CreateCategoryMutationVariables = Exact<{
  input: CreateBasicEducationCategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createBasicEducationCategory: { __typename?: 'BasicEducationCategoryWithDetails', id: any, details: Array<{ __typename?: 'BasicEducationCategoryDetails', language: Language, name: string, backgroundImageUrl: string } | null> } } };

export type CreateEducationPostMutationVariables = Exact<{
  input: CreateBasicEducationPostInput;
}>;


export type CreateEducationPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createBasicEducationPost: { __typename?: 'BasicEducationPostWithDetails', id: any, details: Array<{ __typename?: 'BasicEducationPostDetails', language: Language, title: string, content: string } | null> } } };

export type CreateFrequentlyAskedQuestionMutationVariables = Exact<{
  englishQuestion: Scalars['String'];
  englishAnswer: Scalars['String'];
  icelandicQuestion: Scalars['String'];
  icelandicAnswer: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
}>;


export type CreateFrequentlyAskedQuestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createFrequentlyAskedQuestion: { __typename?: 'FrequentlyAskedQuestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'FrequentlyAskedQuestionDetails', question: string, answer: string } | null> } } };

export type CreateGeneralRecommendationMutationVariables = Exact<{
  input: CreateGeneralRecommendationInput;
}>;


export type CreateGeneralRecommendationMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createGeneralRecommendation: { __typename?: 'GeneralRecommendationWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GeneralRecommendationDetails', hint: string, advice: string } | null> } } };

export type CreateGoalSuggestionMutationVariables = Exact<{
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
}>;


export type CreateGoalSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createGoalSuggestion: { __typename?: 'GoalSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GoalSuggestionDetails', language: Language, content: string } | null> } } };

export type CreateSleepAidPostMutationVariables = Exact<{
  input: CreateSleepAidPostInput;
}>;


export type CreateSleepAidPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createSleepAidPost: { __typename?: 'SleepAidPostWithDetailsWithDetails', id: any, details: Array<{ __typename?: 'SleepAidPostDetails', language: Language, content: string, title: string, videoUrl: string, videoThumbnailUrl: string, videoDuration: any } | null> } } };

export type CreateSleepHabitSuggestionMutationVariables = Exact<{
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
}>;


export type CreateSleepHabitSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createSleepHabitSuggestion: { __typename?: 'SleepHabitSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'SleepHabitSuggestionDetails', language: Language, content: string } | null> } } };

export type CreateTreatmentMutationVariables = Exact<{
  input: CreateTreatmentInput;
}>;


export type CreateTreatmentMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', createTreatment: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteCategoryMutationVariables = Exact<{
  input: DeleteBasicEducationCategoryInput;
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteBasicEducationCategory: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteEducationPostMutationVariables = Exact<{
  input: DeleteBasicEducationPostInput;
}>;


export type DeleteEducationPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteBasicEducationPost: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteFrequentlyAskedQuestionMutationVariables = Exact<{
  frequentlyAskedQuestionId: Scalars['FrequentlyAskedQuestionId'];
}>;


export type DeleteFrequentlyAskedQuestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteFrequentlyAskedQuestion: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteGeneralRecommendationMutationVariables = Exact<{
  input: DeleteGeneralRecommendationInput;
}>;


export type DeleteGeneralRecommendationMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteGeneralRecommendation: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteGoalSuggestionMutationVariables = Exact<{
  goalSuggestionId: Scalars['GoalSuggestionId'];
}>;


export type DeleteGoalSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteGoalSuggestion: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteSleepAidPostMutationVariables = Exact<{
  input: DeleteSleepAidPostInput;
}>;


export type DeleteSleepAidPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteSleepAidPost: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteSleepHabitSuggestionMutationVariables = Exact<{
  sleepHabitSuggestionId: Scalars['SleepHabitSuggestionId'];
}>;


export type DeleteSleepHabitSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteSleepHabitSuggestion: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteTreatmentMutationVariables = Exact<{
  input: DeleteTreatmentInput;
}>;


export type DeleteTreatmentMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteTreatment: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteTreatmentSleepAdviceMutationVariables = Exact<{
  input: DeleteTreatmentWeekSleepAdviceInput;
}>;


export type DeleteTreatmentSleepAdviceMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteTreatmentSleepAdvice: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteTreatmentVideoMutationVariables = Exact<{
  input: DeleteTreatmentWeekVideoInput;
}>;


export type DeleteTreatmentVideoMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteTreatmentVideo: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteTreatmentWeekMutationVariables = Exact<{
  input: DeleteTreatmentWeekInput;
}>;


export type DeleteTreatmentWeekMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', deleteTreatmentWeek: { __typename?: 'MutationResponse', success: boolean } } };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', login: { __typename?: 'AuthMutationResponse', accessToken: string, refreshToken: string, identity: { __typename?: 'Identity', id: any, email: string, name?: string | null } } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', logout: { __typename?: 'MutationResponse', success: boolean } } };

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', refreshAccessToken: { __typename?: 'AuthMutationResponse', accessToken: string, refreshToken: string, identity: { __typename?: 'Identity', id: any, email: string, name?: string | null } } } };

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateBasicEducationCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateBasicEducationCategory: { __typename?: 'BasicEducationCategoryWithDetails', id: any, priority: number, details: Array<{ __typename?: 'BasicEducationCategoryDetails', language: Language, name: string, backgroundImageUrl: string } | null> } } };

export type UpdateEducationPostMutationVariables = Exact<{
  input: UpdateBasicEducationPostInput;
}>;


export type UpdateEducationPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateBasicEducationPost: { __typename?: 'BasicEducationPostWithDetails', id: any, priority: number, details: Array<{ __typename?: 'BasicEducationPostDetails', language: Language, title: string, content: string } | null> } } };

export type UpdateFrequentlyAskedQuestionMutationVariables = Exact<{
  frequentlyAskedQuestionId: Scalars['FrequentlyAskedQuestionId'];
  englishQuestion: Scalars['String'];
  englishAnswer: Scalars['String'];
  icelandicQuestion: Scalars['String'];
  icelandicAnswer: Scalars['String'];
  priority: Scalars['Int'];
}>;


export type UpdateFrequentlyAskedQuestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateFrequentlyAskedQuestion: { __typename?: 'FrequentlyAskedQuestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'FrequentlyAskedQuestionDetails', question: string, answer: string } | null> } } };

export type UpdateGeneralRecommendationMutationVariables = Exact<{
  input: UpdateGeneralRecommendationInput;
}>;


export type UpdateGeneralRecommendationMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateGeneralRecommendation: { __typename?: 'GeneralRecommendationWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GeneralRecommendationDetails', hint: string, advice: string } | null> } } };

export type UpdateGoalSuggestionMutationVariables = Exact<{
  goalSuggestionId: Scalars['GoalSuggestionId'];
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority: Scalars['Int'];
}>;


export type UpdateGoalSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateGoalSuggestion: { __typename?: 'GoalSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GoalSuggestionDetails', language: Language, content: string } | null> } } };

export type UpdateSleepAidPostMutationVariables = Exact<{
  input: UpdateSleepAidPostInput;
}>;


export type UpdateSleepAidPostMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateSleepAidPost: { __typename?: 'SleepAidPostWithDetailsWithDetails', id: any, priority: number, details: Array<{ __typename?: 'SleepAidPostDetails', language: Language, content: string, title: string, videoUrl: string, videoThumbnailUrl: string, videoDuration: any } | null> } } };

export type UpdateSleepHabitSuggestionMutationVariables = Exact<{
  sleepHabitSuggestionId: Scalars['SleepHabitSuggestionId'];
  englishContent: Scalars['String'];
  icelandicContent: Scalars['String'];
  priority: Scalars['Int'];
}>;


export type UpdateSleepHabitSuggestionMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateSleepHabitSuggestion: { __typename?: 'SleepHabitSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'SleepHabitSuggestionDetails', language: Language, content: string } | null> } } };

export type UpdateTreatmentMutationVariables = Exact<{
  input: UpdateTreatmentInput;
}>;


export type UpdateTreatmentMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateTreatment: { __typename?: 'MutationResponse', success: boolean } } };

export type UpdateTreatmentSleepAdviceMutationVariables = Exact<{
  input: UpdateTreatmentSleepAdviceInput;
}>;


export type UpdateTreatmentSleepAdviceMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateTreatmentSleepAdvice: { __typename?: 'MutationResponse', success: boolean } } };

export type UpdateTreatmentWeekMutationVariables = Exact<{
  input: UpdateTreatmentWeekInput;
}>;


export type UpdateTreatmentWeekMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateTreatmentWeek: { __typename?: 'MutationResponse', success: boolean } } };

export type UpdateTreatmentVideoMutationVariables = Exact<{
  input: UpdateTreatmentVideoInput;
}>;


export type UpdateTreatmentVideoMutation = { __typename?: 'Mutation', content: { __typename?: 'ContentMutationGroup', updateTreatmentVideo: { __typename?: 'MutationResponse', success: boolean } } };

export type UserBanMutationVariables = Exact<{
  identityId: Scalars['IdentityId'];
}>;


export type UserBanMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', ban: { __typename?: 'MutationResponse', success: boolean } } };

export type UserDeleteMutationVariables = Exact<{
  identityId: Scalars['IdentityId'];
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', delete: { __typename?: 'MutationResponse', success: boolean } } };

export type UserUnbanMutationVariables = Exact<{
  identityId: Scalars['IdentityId'];
}>;


export type UserUnbanMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', unban: { __typename?: 'MutationResponse', success: boolean } } };

export type CategoriesQueryVariables = Exact<{
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
  meta?: InputMaybe<ListMetadataInput>;
}>;


export type CategoriesQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listBasicEducationCategoriesWithDetails: { __typename?: 'BasicEducationCategoryWithDetailsList', items: Array<{ __typename?: 'BasicEducationCategoryWithDetails', id: any, priority: number, details: Array<{ __typename?: 'BasicEducationCategoryDetails', language: Language, name: string, backgroundImageUrl: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type EducationPostsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type EducationPostsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listBasicEducationPostsWithDetails: { __typename?: 'BasicEducationPostWithDetailsList', items: Array<{ __typename?: 'BasicEducationPostWithDetails', id: any, basicEducationCategoryId: any, priority: number, details: Array<{ __typename?: 'BasicEducationPostDetails', language: Language, title: string, content: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type FrequentlyAskedQuestionsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type FrequentlyAskedQuestionsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listFrequentlyAskedQuestionsWithDetails: { __typename?: 'FrequentlyAskedQuestionWithDetailsList', items: Array<{ __typename?: 'FrequentlyAskedQuestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'FrequentlyAskedQuestionDetails', language: Language, question: string, answer: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type GeneralRecommendationsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type GeneralRecommendationsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listGeneralRecommendationsWithDetails: { __typename?: 'GeneralRecommendationWithDetailsList', items: Array<{ __typename?: 'GeneralRecommendationWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GeneralRecommendationDetails', language: Language, advice: string, hint: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type GetCategoryImgUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoryImgUrlQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getBasicEducationCategoryBackgroundImageUploadUrl: { __typename?: 'FileUploadUrl', expiresAt: any, url: string } } };

export type GetMyDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyDetailsQuery = { __typename?: 'Query', identity: { __typename?: 'IdentityQueryGroup', getMyDetails: { __typename?: 'Identity', id: any, name?: string | null, email: string } } };

export type GetTreatmentVideoThumbnailUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreatmentVideoThumbnailUploadUrlQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getTreatmentVideoThumbnailUploadUrl: { __typename?: 'FileUploadUrl', url: string } } };

export type GetTreatmentVideoUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreatmentVideoUploadUrlQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getTreatmentVideoUploadUrl: { __typename?: 'FileUploadUrl', url: string } } };

export type GetVideoThumbnailUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoThumbnailUploadUrlQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getSleepAidPostVideoUploadUrl: { __typename?: 'FileUploadUrl', url: string } } };

export type GetVideoUploadUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoUploadUrlQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getSleepAidPostVideoUploadUrl: { __typename?: 'FileUploadUrl', url: string } } };

export type GoalSuggestionsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type GoalSuggestionsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listGoalSuggestionsWithDetails: { __typename?: 'GoalSuggestionWithDetailsList', items: Array<{ __typename?: 'GoalSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'GoalSuggestionDetails', language: Language, content: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type ListCategoriesQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
}>;


export type ListCategoriesQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listBasicEducationCategories: { __typename?: 'BasicEducationCategoryList', items: Array<{ __typename?: 'BasicEducationCategory', id: any, name: string }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type SleepAidPostsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type SleepAidPostsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listSleepAidPostsWithDetails: { __typename?: 'SleepAidPostWithDetailsList', items: Array<{ __typename?: 'SleepAidPostWithDetailsWithDetails', id: any, priority: number, details: Array<{ __typename?: 'SleepAidPostDetails', language: Language, title: string, content: string, videoUrl: string, videoThumbnailUrl: string, videoDuration: any } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type SleepHabitSuggestionsQueryVariables = Exact<{
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
}>;


export type SleepHabitSuggestionsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listSleepHabitSuggestionsWithDetails: { __typename?: 'SleepHabitSuggestionWithDetailsList', items: Array<{ __typename?: 'SleepHabitSuggestionWithDetails', id: any, priority: number, details: Array<{ __typename?: 'SleepHabitSuggestionDetails', language: Language, content: string } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type GetTreatmentWeekWithDetailsQueryVariables = Exact<{
  treatmentWeekId: Scalars['TreatmentWeekId'];
}>;


export type GetTreatmentWeekWithDetailsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', getTreatmentWeekWithDetails: { __typename?: 'TreatmentWeekWithDetailsContent', sleepAdvices: Array<{ __typename?: 'TreatmentSleepAdviceWithDetails', id: any, priority: number, details: Array<{ __typename?: 'TreatmentSleepAdviceDetails', title: string, content: string, language: Language } | null> } | null>, videos: Array<{ __typename?: 'TreatmentVideoWithDetails', id: any, priority: number, details: Array<{ __typename?: 'TreatmentVideoDetails', language: Language, title: string, content: string, videoUrl: string, videoThumbnailUrl: string, videoDuration: any } | null> } | null> } } };

export type ListTreatmentsWithDetailsQueryVariables = Exact<{
  sortDefinition?: InputMaybe<ContentSortDefinitionInput>;
  meta?: InputMaybe<ListMetadataInput>;
}>;


export type ListTreatmentsWithDetailsQuery = { __typename?: 'Query', content: { __typename?: 'ContentQueryGroup', listTreatmentsWithDetails: { __typename?: 'TreatmentWithDetailsList', items: Array<{ __typename?: 'TreatmentWithDetails', id: any, priority: number, details: Array<{ __typename?: 'TreatmentDetails', title: string, description: string, language: Language } | null>, weeks: Array<{ __typename?: 'TreatmentWeekContent', id: any, number: number } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export type UsersQueryVariables = Exact<{
  sortDefinition?: InputMaybe<IdentitySortDefinitionInput>;
  meta?: InputMaybe<ListMetadataInput>;
  filters?: InputMaybe<IdentityListFiltersInput>;
}>;


export type UsersQuery = { __typename?: 'Query', identity: { __typename?: 'IdentityQueryGroup', list: { __typename?: 'IdentityList', items: Array<{ __typename?: 'Identity', id: any, name?: string | null, email: string, profilePictureUrl?: string | null, registeredAt: any, isBanned: boolean, isEmailConfirmed: boolean }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null, totalCount: any } } } };

export const MetaFragmentDoc = `
    fragment Meta on ListMetadata {
  pageSize
  pageCursor
  nextPageCursor
  totalCount
}
    `;
export const AddSleepAdviceToTreatmentWeekDocument = `
    mutation addSleepAdviceToTreatmentWeek($input: AddSleepAdviceToTreatmentWeekInput!) {
  content {
    addSleepAdviceToTreatmentWeek(input: $input) {
      success
    }
  }
}
    `;
export const useAddSleepAdviceToTreatmentWeekMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSleepAdviceToTreatmentWeekMutation, TError, AddSleepAdviceToTreatmentWeekMutationVariables, TContext>) =>
    useMutation<AddSleepAdviceToTreatmentWeekMutation, TError, AddSleepAdviceToTreatmentWeekMutationVariables, TContext>(
      ['addSleepAdviceToTreatmentWeek'],
      (variables?: AddSleepAdviceToTreatmentWeekMutationVariables) => fetchData<AddSleepAdviceToTreatmentWeekMutation, AddSleepAdviceToTreatmentWeekMutationVariables>(AddSleepAdviceToTreatmentWeekDocument, variables)(),
      options
    );
export const AddVideoToTreatmentWeekDocument = `
    mutation addVideoToTreatmentWeek($input: AddVideoToTreatmentWeekInput!) {
  content {
    addVideoToTreatmentWeek(input: $input) {
      success
    }
  }
}
    `;
export const useAddVideoToTreatmentWeekMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddVideoToTreatmentWeekMutation, TError, AddVideoToTreatmentWeekMutationVariables, TContext>) =>
    useMutation<AddVideoToTreatmentWeekMutation, TError, AddVideoToTreatmentWeekMutationVariables, TContext>(
      ['addVideoToTreatmentWeek'],
      (variables?: AddVideoToTreatmentWeekMutationVariables) => fetchData<AddVideoToTreatmentWeekMutation, AddVideoToTreatmentWeekMutationVariables>(AddVideoToTreatmentWeekDocument, variables)(),
      options
    );
export const AddWeekToTreatmentDocument = `
    mutation addWeekToTreatment($input: AddWeekToTreatmentInput!) {
  content {
    addWeekToTreatment(input: $input) {
      success
    }
  }
}
    `;
export const useAddWeekToTreatmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddWeekToTreatmentMutation, TError, AddWeekToTreatmentMutationVariables, TContext>) =>
    useMutation<AddWeekToTreatmentMutation, TError, AddWeekToTreatmentMutationVariables, TContext>(
      ['addWeekToTreatment'],
      (variables?: AddWeekToTreatmentMutationVariables) => fetchData<AddWeekToTreatmentMutation, AddWeekToTreatmentMutationVariables>(AddWeekToTreatmentDocument, variables)(),
      options
    );
export const CreateCategoryDocument = `
    mutation createCategory($input: CreateBasicEducationCategoryInput!) {
  content {
    createBasicEducationCategory(input: $input) {
      id
      details {
        language
        name
        backgroundImageUrl
      }
    }
  }
}
    `;
export const useCreateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>) =>
    useMutation<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>(
      ['createCategory'],
      (variables?: CreateCategoryMutationVariables) => fetchData<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, variables)(),
      options
    );
export const CreateEducationPostDocument = `
    mutation createEducationPost($input: CreateBasicEducationPostInput!) {
  content {
    createBasicEducationPost(input: $input) {
      id
      details {
        language
        title
        content
      }
    }
  }
}
    `;
export const useCreateEducationPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEducationPostMutation, TError, CreateEducationPostMutationVariables, TContext>) =>
    useMutation<CreateEducationPostMutation, TError, CreateEducationPostMutationVariables, TContext>(
      ['createEducationPost'],
      (variables?: CreateEducationPostMutationVariables) => fetchData<CreateEducationPostMutation, CreateEducationPostMutationVariables>(CreateEducationPostDocument, variables)(),
      options
    );
export const CreateFrequentlyAskedQuestionDocument = `
    mutation createFrequentlyAskedQuestion($englishQuestion: String!, $englishAnswer: String!, $icelandicQuestion: String!, $icelandicAnswer: String!, $priority: Int) {
  content {
    createFrequentlyAskedQuestion(
      input: {englishQuestion: $englishQuestion, englishAnswer: $englishAnswer, icelandicQuestion: $icelandicQuestion, icelandicAnswer: $icelandicAnswer, priority: $priority}
    ) {
      id
      priority
      details {
        question
        answer
      }
    }
  }
}
    `;
export const useCreateFrequentlyAskedQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateFrequentlyAskedQuestionMutation, TError, CreateFrequentlyAskedQuestionMutationVariables, TContext>) =>
    useMutation<CreateFrequentlyAskedQuestionMutation, TError, CreateFrequentlyAskedQuestionMutationVariables, TContext>(
      ['createFrequentlyAskedQuestion'],
      (variables?: CreateFrequentlyAskedQuestionMutationVariables) => fetchData<CreateFrequentlyAskedQuestionMutation, CreateFrequentlyAskedQuestionMutationVariables>(CreateFrequentlyAskedQuestionDocument, variables)(),
      options
    );
export const CreateGeneralRecommendationDocument = `
    mutation createGeneralRecommendation($input: CreateGeneralRecommendationInput!) {
  content {
    createGeneralRecommendation(input: $input) {
      id
      priority
      details {
        hint
        advice
      }
    }
  }
}
    `;
export const useCreateGeneralRecommendationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateGeneralRecommendationMutation, TError, CreateGeneralRecommendationMutationVariables, TContext>) =>
    useMutation<CreateGeneralRecommendationMutation, TError, CreateGeneralRecommendationMutationVariables, TContext>(
      ['createGeneralRecommendation'],
      (variables?: CreateGeneralRecommendationMutationVariables) => fetchData<CreateGeneralRecommendationMutation, CreateGeneralRecommendationMutationVariables>(CreateGeneralRecommendationDocument, variables)(),
      options
    );
export const CreateGoalSuggestionDocument = `
    mutation createGoalSuggestion($englishContent: String!, $icelandicContent: String!, $priority: Int) {
  content {
    createGoalSuggestion(
      input: {englishContent: $englishContent, icelandicContent: $icelandicContent, priority: $priority}
    ) {
      id
      priority
      details {
        language
        content
      }
    }
  }
}
    `;
export const useCreateGoalSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateGoalSuggestionMutation, TError, CreateGoalSuggestionMutationVariables, TContext>) =>
    useMutation<CreateGoalSuggestionMutation, TError, CreateGoalSuggestionMutationVariables, TContext>(
      ['createGoalSuggestion'],
      (variables?: CreateGoalSuggestionMutationVariables) => fetchData<CreateGoalSuggestionMutation, CreateGoalSuggestionMutationVariables>(CreateGoalSuggestionDocument, variables)(),
      options
    );
export const CreateSleepAidPostDocument = `
    mutation createSleepAidPost($input: CreateSleepAidPostInput!) {
  content {
    createSleepAidPost(input: $input) {
      id
      details {
        language
        content
        title
        videoUrl
        videoThumbnailUrl
        videoDuration
      }
    }
  }
}
    `;
export const useCreateSleepAidPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSleepAidPostMutation, TError, CreateSleepAidPostMutationVariables, TContext>) =>
    useMutation<CreateSleepAidPostMutation, TError, CreateSleepAidPostMutationVariables, TContext>(
      ['createSleepAidPost'],
      (variables?: CreateSleepAidPostMutationVariables) => fetchData<CreateSleepAidPostMutation, CreateSleepAidPostMutationVariables>(CreateSleepAidPostDocument, variables)(),
      options
    );
export const CreateSleepHabitSuggestionDocument = `
    mutation createSleepHabitSuggestion($englishContent: String!, $icelandicContent: String!, $priority: Int) {
  content {
    createSleepHabitSuggestion(
      input: {englishContent: $englishContent, icelandicContent: $icelandicContent, priority: $priority}
    ) {
      id
      priority
      details {
        language
        content
      }
    }
  }
}
    `;
export const useCreateSleepHabitSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSleepHabitSuggestionMutation, TError, CreateSleepHabitSuggestionMutationVariables, TContext>) =>
    useMutation<CreateSleepHabitSuggestionMutation, TError, CreateSleepHabitSuggestionMutationVariables, TContext>(
      ['createSleepHabitSuggestion'],
      (variables?: CreateSleepHabitSuggestionMutationVariables) => fetchData<CreateSleepHabitSuggestionMutation, CreateSleepHabitSuggestionMutationVariables>(CreateSleepHabitSuggestionDocument, variables)(),
      options
    );
export const CreateTreatmentDocument = `
    mutation createTreatment($input: CreateTreatmentInput!) {
  content {
    createTreatment(input: $input) {
      success
    }
  }
}
    `;
export const useCreateTreatmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTreatmentMutation, TError, CreateTreatmentMutationVariables, TContext>) =>
    useMutation<CreateTreatmentMutation, TError, CreateTreatmentMutationVariables, TContext>(
      ['createTreatment'],
      (variables?: CreateTreatmentMutationVariables) => fetchData<CreateTreatmentMutation, CreateTreatmentMutationVariables>(CreateTreatmentDocument, variables)(),
      options
    );
export const DeleteCategoryDocument = `
    mutation deleteCategory($input: DeleteBasicEducationCategoryInput!) {
  content {
    deleteBasicEducationCategory(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>) =>
    useMutation<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>(
      ['deleteCategory'],
      (variables?: DeleteCategoryMutationVariables) => fetchData<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, variables)(),
      options
    );
export const DeleteEducationPostDocument = `
    mutation deleteEducationPost($input: DeleteBasicEducationPostInput!) {
  content {
    deleteBasicEducationPost(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteEducationPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteEducationPostMutation, TError, DeleteEducationPostMutationVariables, TContext>) =>
    useMutation<DeleteEducationPostMutation, TError, DeleteEducationPostMutationVariables, TContext>(
      ['deleteEducationPost'],
      (variables?: DeleteEducationPostMutationVariables) => fetchData<DeleteEducationPostMutation, DeleteEducationPostMutationVariables>(DeleteEducationPostDocument, variables)(),
      options
    );
export const DeleteFrequentlyAskedQuestionDocument = `
    mutation deleteFrequentlyAskedQuestion($frequentlyAskedQuestionId: FrequentlyAskedQuestionId!) {
  content {
    deleteFrequentlyAskedQuestion(input: {id: $frequentlyAskedQuestionId}) {
      success
    }
  }
}
    `;
export const useDeleteFrequentlyAskedQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteFrequentlyAskedQuestionMutation, TError, DeleteFrequentlyAskedQuestionMutationVariables, TContext>) =>
    useMutation<DeleteFrequentlyAskedQuestionMutation, TError, DeleteFrequentlyAskedQuestionMutationVariables, TContext>(
      ['deleteFrequentlyAskedQuestion'],
      (variables?: DeleteFrequentlyAskedQuestionMutationVariables) => fetchData<DeleteFrequentlyAskedQuestionMutation, DeleteFrequentlyAskedQuestionMutationVariables>(DeleteFrequentlyAskedQuestionDocument, variables)(),
      options
    );
export const DeleteGeneralRecommendationDocument = `
    mutation deleteGeneralRecommendation($input: DeleteGeneralRecommendationInput!) {
  content {
    deleteGeneralRecommendation(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteGeneralRecommendationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGeneralRecommendationMutation, TError, DeleteGeneralRecommendationMutationVariables, TContext>) =>
    useMutation<DeleteGeneralRecommendationMutation, TError, DeleteGeneralRecommendationMutationVariables, TContext>(
      ['deleteGeneralRecommendation'],
      (variables?: DeleteGeneralRecommendationMutationVariables) => fetchData<DeleteGeneralRecommendationMutation, DeleteGeneralRecommendationMutationVariables>(DeleteGeneralRecommendationDocument, variables)(),
      options
    );
export const DeleteGoalSuggestionDocument = `
    mutation deleteGoalSuggestion($goalSuggestionId: GoalSuggestionId!) {
  content {
    deleteGoalSuggestion(input: {id: $goalSuggestionId}) {
      success
    }
  }
}
    `;
export const useDeleteGoalSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGoalSuggestionMutation, TError, DeleteGoalSuggestionMutationVariables, TContext>) =>
    useMutation<DeleteGoalSuggestionMutation, TError, DeleteGoalSuggestionMutationVariables, TContext>(
      ['deleteGoalSuggestion'],
      (variables?: DeleteGoalSuggestionMutationVariables) => fetchData<DeleteGoalSuggestionMutation, DeleteGoalSuggestionMutationVariables>(DeleteGoalSuggestionDocument, variables)(),
      options
    );
export const DeleteSleepAidPostDocument = `
    mutation deleteSleepAidPost($input: DeleteSleepAidPostInput!) {
  content {
    deleteSleepAidPost(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteSleepAidPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSleepAidPostMutation, TError, DeleteSleepAidPostMutationVariables, TContext>) =>
    useMutation<DeleteSleepAidPostMutation, TError, DeleteSleepAidPostMutationVariables, TContext>(
      ['deleteSleepAidPost'],
      (variables?: DeleteSleepAidPostMutationVariables) => fetchData<DeleteSleepAidPostMutation, DeleteSleepAidPostMutationVariables>(DeleteSleepAidPostDocument, variables)(),
      options
    );
export const DeleteSleepHabitSuggestionDocument = `
    mutation deleteSleepHabitSuggestion($sleepHabitSuggestionId: SleepHabitSuggestionId!) {
  content {
    deleteSleepHabitSuggestion(input: {id: $sleepHabitSuggestionId}) {
      success
    }
  }
}
    `;
export const useDeleteSleepHabitSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSleepHabitSuggestionMutation, TError, DeleteSleepHabitSuggestionMutationVariables, TContext>) =>
    useMutation<DeleteSleepHabitSuggestionMutation, TError, DeleteSleepHabitSuggestionMutationVariables, TContext>(
      ['deleteSleepHabitSuggestion'],
      (variables?: DeleteSleepHabitSuggestionMutationVariables) => fetchData<DeleteSleepHabitSuggestionMutation, DeleteSleepHabitSuggestionMutationVariables>(DeleteSleepHabitSuggestionDocument, variables)(),
      options
    );
export const DeleteTreatmentDocument = `
    mutation deleteTreatment($input: DeleteTreatmentInput!) {
  content {
    deleteTreatment(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteTreatmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTreatmentMutation, TError, DeleteTreatmentMutationVariables, TContext>) =>
    useMutation<DeleteTreatmentMutation, TError, DeleteTreatmentMutationVariables, TContext>(
      ['deleteTreatment'],
      (variables?: DeleteTreatmentMutationVariables) => fetchData<DeleteTreatmentMutation, DeleteTreatmentMutationVariables>(DeleteTreatmentDocument, variables)(),
      options
    );
export const DeleteTreatmentSleepAdviceDocument = `
    mutation deleteTreatmentSleepAdvice($input: DeleteTreatmentWeekSleepAdviceInput!) {
  content {
    deleteTreatmentSleepAdvice(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteTreatmentSleepAdviceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTreatmentSleepAdviceMutation, TError, DeleteTreatmentSleepAdviceMutationVariables, TContext>) =>
    useMutation<DeleteTreatmentSleepAdviceMutation, TError, DeleteTreatmentSleepAdviceMutationVariables, TContext>(
      ['deleteTreatmentSleepAdvice'],
      (variables?: DeleteTreatmentSleepAdviceMutationVariables) => fetchData<DeleteTreatmentSleepAdviceMutation, DeleteTreatmentSleepAdviceMutationVariables>(DeleteTreatmentSleepAdviceDocument, variables)(),
      options
    );
export const DeleteTreatmentVideoDocument = `
    mutation deleteTreatmentVideo($input: DeleteTreatmentWeekVideoInput!) {
  content {
    deleteTreatmentVideo(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteTreatmentVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTreatmentVideoMutation, TError, DeleteTreatmentVideoMutationVariables, TContext>) =>
    useMutation<DeleteTreatmentVideoMutation, TError, DeleteTreatmentVideoMutationVariables, TContext>(
      ['deleteTreatmentVideo'],
      (variables?: DeleteTreatmentVideoMutationVariables) => fetchData<DeleteTreatmentVideoMutation, DeleteTreatmentVideoMutationVariables>(DeleteTreatmentVideoDocument, variables)(),
      options
    );
export const DeleteTreatmentWeekDocument = `
    mutation deleteTreatmentWeek($input: DeleteTreatmentWeekInput!) {
  content {
    deleteTreatmentWeek(input: $input) {
      success
    }
  }
}
    `;
export const useDeleteTreatmentWeekMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTreatmentWeekMutation, TError, DeleteTreatmentWeekMutationVariables, TContext>) =>
    useMutation<DeleteTreatmentWeekMutation, TError, DeleteTreatmentWeekMutationVariables, TContext>(
      ['deleteTreatmentWeek'],
      (variables?: DeleteTreatmentWeekMutationVariables) => fetchData<DeleteTreatmentWeekMutation, DeleteTreatmentWeekMutationVariables>(DeleteTreatmentWeekDocument, variables)(),
      options
    );
export const LoginDocument = `
    mutation login($input: LoginInput!) {
  identity {
    login(input: $input) {
      accessToken
      refreshToken
      identity {
        id
        email
        name
      }
    }
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['login'],
      (variables?: LoginMutationVariables) => fetchData<LoginMutation, LoginMutationVariables>(LoginDocument, variables)(),
      options
    );
export const LogoutDocument = `
    mutation logout {
  identity {
    logout {
      success
    }
  }
}
    `;
export const useLogoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>) =>
    useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
      ['logout'],
      (variables?: LogoutMutationVariables) => fetchData<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
      options
    );
export const RefreshAccessTokenDocument = `
    mutation refreshAccessToken($refreshToken: String!) {
  identity {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      identity {
        id
        email
        name
      }
    }
  }
}
    `;
export const useRefreshAccessTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RefreshAccessTokenMutation, TError, RefreshAccessTokenMutationVariables, TContext>) =>
    useMutation<RefreshAccessTokenMutation, TError, RefreshAccessTokenMutationVariables, TContext>(
      ['refreshAccessToken'],
      (variables?: RefreshAccessTokenMutationVariables) => fetchData<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, variables)(),
      options
    );
export const UpdateCategoryDocument = `
    mutation updateCategory($input: UpdateBasicEducationCategoryInput!) {
  content {
    updateBasicEducationCategory(input: $input) {
      id
      priority
      details {
        language
        name
        backgroundImageUrl
      }
    }
  }
}
    `;
export const useUpdateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>) =>
    useMutation<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>(
      ['updateCategory'],
      (variables?: UpdateCategoryMutationVariables) => fetchData<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, variables)(),
      options
    );
export const UpdateEducationPostDocument = `
    mutation updateEducationPost($input: UpdateBasicEducationPostInput!) {
  content {
    updateBasicEducationPost(input: $input) {
      id
      priority
      details {
        language
        title
        content
      }
    }
  }
}
    `;
export const useUpdateEducationPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateEducationPostMutation, TError, UpdateEducationPostMutationVariables, TContext>) =>
    useMutation<UpdateEducationPostMutation, TError, UpdateEducationPostMutationVariables, TContext>(
      ['updateEducationPost'],
      (variables?: UpdateEducationPostMutationVariables) => fetchData<UpdateEducationPostMutation, UpdateEducationPostMutationVariables>(UpdateEducationPostDocument, variables)(),
      options
    );
export const UpdateFrequentlyAskedQuestionDocument = `
    mutation updateFrequentlyAskedQuestion($frequentlyAskedQuestionId: FrequentlyAskedQuestionId!, $englishQuestion: String!, $englishAnswer: String!, $icelandicQuestion: String!, $icelandicAnswer: String!, $priority: Int!) {
  content {
    updateFrequentlyAskedQuestion(
      input: {id: $frequentlyAskedQuestionId, englishQuestion: $englishQuestion, englishAnswer: $englishAnswer, icelandicQuestion: $icelandicQuestion, icelandicAnswer: $icelandicAnswer, priority: $priority}
    ) {
      id
      priority
      details {
        question
        answer
      }
    }
  }
}
    `;
export const useUpdateFrequentlyAskedQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateFrequentlyAskedQuestionMutation, TError, UpdateFrequentlyAskedQuestionMutationVariables, TContext>) =>
    useMutation<UpdateFrequentlyAskedQuestionMutation, TError, UpdateFrequentlyAskedQuestionMutationVariables, TContext>(
      ['updateFrequentlyAskedQuestion'],
      (variables?: UpdateFrequentlyAskedQuestionMutationVariables) => fetchData<UpdateFrequentlyAskedQuestionMutation, UpdateFrequentlyAskedQuestionMutationVariables>(UpdateFrequentlyAskedQuestionDocument, variables)(),
      options
    );
export const UpdateGeneralRecommendationDocument = `
    mutation updateGeneralRecommendation($input: UpdateGeneralRecommendationInput!) {
  content {
    updateGeneralRecommendation(input: $input) {
      id
      priority
      details {
        hint
        advice
      }
    }
  }
}
    `;
export const useUpdateGeneralRecommendationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateGeneralRecommendationMutation, TError, UpdateGeneralRecommendationMutationVariables, TContext>) =>
    useMutation<UpdateGeneralRecommendationMutation, TError, UpdateGeneralRecommendationMutationVariables, TContext>(
      ['updateGeneralRecommendation'],
      (variables?: UpdateGeneralRecommendationMutationVariables) => fetchData<UpdateGeneralRecommendationMutation, UpdateGeneralRecommendationMutationVariables>(UpdateGeneralRecommendationDocument, variables)(),
      options
    );
export const UpdateGoalSuggestionDocument = `
    mutation updateGoalSuggestion($goalSuggestionId: GoalSuggestionId!, $englishContent: String!, $icelandicContent: String!, $priority: Int!) {
  content {
    updateGoalSuggestion(
      input: {id: $goalSuggestionId, englishContent: $englishContent, icelandicContent: $icelandicContent, priority: $priority}
    ) {
      id
      priority
      details {
        language
        content
      }
    }
  }
}
    `;
export const useUpdateGoalSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateGoalSuggestionMutation, TError, UpdateGoalSuggestionMutationVariables, TContext>) =>
    useMutation<UpdateGoalSuggestionMutation, TError, UpdateGoalSuggestionMutationVariables, TContext>(
      ['updateGoalSuggestion'],
      (variables?: UpdateGoalSuggestionMutationVariables) => fetchData<UpdateGoalSuggestionMutation, UpdateGoalSuggestionMutationVariables>(UpdateGoalSuggestionDocument, variables)(),
      options
    );
export const UpdateSleepAidPostDocument = `
    mutation updateSleepAidPost($input: UpdateSleepAidPostInput!) {
  content {
    updateSleepAidPost(input: $input) {
      id
      priority
      details {
        language
        content
        title
        videoUrl
        videoThumbnailUrl
        videoDuration
      }
    }
  }
}
    `;
export const useUpdateSleepAidPostMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSleepAidPostMutation, TError, UpdateSleepAidPostMutationVariables, TContext>) =>
    useMutation<UpdateSleepAidPostMutation, TError, UpdateSleepAidPostMutationVariables, TContext>(
      ['updateSleepAidPost'],
      (variables?: UpdateSleepAidPostMutationVariables) => fetchData<UpdateSleepAidPostMutation, UpdateSleepAidPostMutationVariables>(UpdateSleepAidPostDocument, variables)(),
      options
    );
export const UpdateSleepHabitSuggestionDocument = `
    mutation updateSleepHabitSuggestion($sleepHabitSuggestionId: SleepHabitSuggestionId!, $englishContent: String!, $icelandicContent: String!, $priority: Int!) {
  content {
    updateSleepHabitSuggestion(
      input: {id: $sleepHabitSuggestionId, englishContent: $englishContent, icelandicContent: $icelandicContent, priority: $priority}
    ) {
      id
      priority
      details {
        language
        content
      }
    }
  }
}
    `;
export const useUpdateSleepHabitSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSleepHabitSuggestionMutation, TError, UpdateSleepHabitSuggestionMutationVariables, TContext>) =>
    useMutation<UpdateSleepHabitSuggestionMutation, TError, UpdateSleepHabitSuggestionMutationVariables, TContext>(
      ['updateSleepHabitSuggestion'],
      (variables?: UpdateSleepHabitSuggestionMutationVariables) => fetchData<UpdateSleepHabitSuggestionMutation, UpdateSleepHabitSuggestionMutationVariables>(UpdateSleepHabitSuggestionDocument, variables)(),
      options
    );
export const UpdateTreatmentDocument = `
    mutation updateTreatment($input: UpdateTreatmentInput!) {
  content {
    updateTreatment(input: $input) {
      success
    }
  }
}
    `;
export const useUpdateTreatmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTreatmentMutation, TError, UpdateTreatmentMutationVariables, TContext>) =>
    useMutation<UpdateTreatmentMutation, TError, UpdateTreatmentMutationVariables, TContext>(
      ['updateTreatment'],
      (variables?: UpdateTreatmentMutationVariables) => fetchData<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>(UpdateTreatmentDocument, variables)(),
      options
    );
export const UpdateTreatmentSleepAdviceDocument = `
    mutation updateTreatmentSleepAdvice($input: UpdateTreatmentSleepAdviceInput!) {
  content {
    updateTreatmentSleepAdvice(input: $input) {
      success
    }
  }
}
    `;
export const useUpdateTreatmentSleepAdviceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTreatmentSleepAdviceMutation, TError, UpdateTreatmentSleepAdviceMutationVariables, TContext>) =>
    useMutation<UpdateTreatmentSleepAdviceMutation, TError, UpdateTreatmentSleepAdviceMutationVariables, TContext>(
      ['updateTreatmentSleepAdvice'],
      (variables?: UpdateTreatmentSleepAdviceMutationVariables) => fetchData<UpdateTreatmentSleepAdviceMutation, UpdateTreatmentSleepAdviceMutationVariables>(UpdateTreatmentSleepAdviceDocument, variables)(),
      options
    );
export const UpdateTreatmentWeekDocument = `
    mutation updateTreatmentWeek($input: UpdateTreatmentWeekInput!) {
  content {
    updateTreatmentWeek(input: $input) {
      success
    }
  }
}
    `;
export const useUpdateTreatmentWeekMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTreatmentWeekMutation, TError, UpdateTreatmentWeekMutationVariables, TContext>) =>
    useMutation<UpdateTreatmentWeekMutation, TError, UpdateTreatmentWeekMutationVariables, TContext>(
      ['updateTreatmentWeek'],
      (variables?: UpdateTreatmentWeekMutationVariables) => fetchData<UpdateTreatmentWeekMutation, UpdateTreatmentWeekMutationVariables>(UpdateTreatmentWeekDocument, variables)(),
      options
    );
export const UpdateTreatmentVideoDocument = `
    mutation updateTreatmentVideo($input: UpdateTreatmentVideoInput!) {
  content {
    updateTreatmentVideo(input: $input) {
      success
    }
  }
}
    `;
export const useUpdateTreatmentVideoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTreatmentVideoMutation, TError, UpdateTreatmentVideoMutationVariables, TContext>) =>
    useMutation<UpdateTreatmentVideoMutation, TError, UpdateTreatmentVideoMutationVariables, TContext>(
      ['updateTreatmentVideo'],
      (variables?: UpdateTreatmentVideoMutationVariables) => fetchData<UpdateTreatmentVideoMutation, UpdateTreatmentVideoMutationVariables>(UpdateTreatmentVideoDocument, variables)(),
      options
    );
export const UserBanDocument = `
    mutation userBan($identityId: IdentityId!) {
  identity {
    ban(input: {identityId: $identityId}) {
      success
    }
  }
}
    `;
export const useUserBanMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UserBanMutation, TError, UserBanMutationVariables, TContext>) =>
    useMutation<UserBanMutation, TError, UserBanMutationVariables, TContext>(
      ['userBan'],
      (variables?: UserBanMutationVariables) => fetchData<UserBanMutation, UserBanMutationVariables>(UserBanDocument, variables)(),
      options
    );
export const UserDeleteDocument = `
    mutation userDelete($identityId: IdentityId!) {
  identity {
    delete(input: {identityId: $identityId}) {
      success
    }
  }
}
    `;
export const useUserDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>) =>
    useMutation<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>(
      ['userDelete'],
      (variables?: UserDeleteMutationVariables) => fetchData<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, variables)(),
      options
    );
export const UserUnbanDocument = `
    mutation userUnban($identityId: IdentityId!) {
  identity {
    unban(input: {identityId: $identityId}) {
      success
    }
  }
}
    `;
export const useUserUnbanMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UserUnbanMutation, TError, UserUnbanMutationVariables, TContext>) =>
    useMutation<UserUnbanMutation, TError, UserUnbanMutationVariables, TContext>(
      ['userUnban'],
      (variables?: UserUnbanMutationVariables) => fetchData<UserUnbanMutation, UserUnbanMutationVariables>(UserUnbanDocument, variables)(),
      options
    );
export const CategoriesDocument = `
    query categories($sortDefinition: ContentSortDefinitionInput, $meta: ListMetadataInput) {
  content {
    listBasicEducationCategoriesWithDetails(
      sortDefinition: $sortDefinition
      meta: $meta
    ) {
      items {
        id
        priority
        details {
          language
          name
          backgroundImageUrl
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useCategoriesQuery = <
      TData = CategoriesQuery,
      TError = unknown
    >(
      variables?: CategoriesQueryVariables,
      options?: UseQueryOptions<CategoriesQuery, TError, TData>
    ) =>
    useQuery<CategoriesQuery, TError, TData>(
      variables === undefined ? ['categories'] : ['categories', variables],
      fetchData<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, variables),
      options
    );
export const EducationPostsDocument = `
    query educationPosts($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listBasicEducationPostsWithDetails(meta: $meta, sortDefinition: $sortDefinition) {
      items {
        id
        basicEducationCategoryId
        priority
        details {
          language
          title
          content
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useEducationPostsQuery = <
      TData = EducationPostsQuery,
      TError = unknown
    >(
      variables?: EducationPostsQueryVariables,
      options?: UseQueryOptions<EducationPostsQuery, TError, TData>
    ) =>
    useQuery<EducationPostsQuery, TError, TData>(
      variables === undefined ? ['educationPosts'] : ['educationPosts', variables],
      fetchData<EducationPostsQuery, EducationPostsQueryVariables>(EducationPostsDocument, variables),
      options
    );
export const FrequentlyAskedQuestionsDocument = `
    query frequentlyAskedQuestions($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listFrequentlyAskedQuestionsWithDetails(
      meta: $meta
      sortDefinition: $sortDefinition
    ) {
      items {
        id
        details {
          language
          question
          answer
        }
        priority
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useFrequentlyAskedQuestionsQuery = <
      TData = FrequentlyAskedQuestionsQuery,
      TError = unknown
    >(
      variables?: FrequentlyAskedQuestionsQueryVariables,
      options?: UseQueryOptions<FrequentlyAskedQuestionsQuery, TError, TData>
    ) =>
    useQuery<FrequentlyAskedQuestionsQuery, TError, TData>(
      variables === undefined ? ['frequentlyAskedQuestions'] : ['frequentlyAskedQuestions', variables],
      fetchData<FrequentlyAskedQuestionsQuery, FrequentlyAskedQuestionsQueryVariables>(FrequentlyAskedQuestionsDocument, variables),
      options
    );
export const GeneralRecommendationsDocument = `
    query generalRecommendations($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listGeneralRecommendationsWithDetails(
      meta: $meta
      sortDefinition: $sortDefinition
    ) {
      items {
        id
        priority
        details {
          language
          advice
          hint
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useGeneralRecommendationsQuery = <
      TData = GeneralRecommendationsQuery,
      TError = unknown
    >(
      variables?: GeneralRecommendationsQueryVariables,
      options?: UseQueryOptions<GeneralRecommendationsQuery, TError, TData>
    ) =>
    useQuery<GeneralRecommendationsQuery, TError, TData>(
      variables === undefined ? ['generalRecommendations'] : ['generalRecommendations', variables],
      fetchData<GeneralRecommendationsQuery, GeneralRecommendationsQueryVariables>(GeneralRecommendationsDocument, variables),
      options
    );
export const GetCategoryImgUrlDocument = `
    query getCategoryImgUrl {
  content {
    getBasicEducationCategoryBackgroundImageUploadUrl {
      expiresAt
      url
    }
  }
}
    `;
export const useGetCategoryImgUrlQuery = <
      TData = GetCategoryImgUrlQuery,
      TError = unknown
    >(
      variables?: GetCategoryImgUrlQueryVariables,
      options?: UseQueryOptions<GetCategoryImgUrlQuery, TError, TData>
    ) =>
    useQuery<GetCategoryImgUrlQuery, TError, TData>(
      variables === undefined ? ['getCategoryImgUrl'] : ['getCategoryImgUrl', variables],
      fetchData<GetCategoryImgUrlQuery, GetCategoryImgUrlQueryVariables>(GetCategoryImgUrlDocument, variables),
      options
    );
export const GetMyDetailsDocument = `
    query getMyDetails {
  identity {
    getMyDetails {
      id
      name
      email
    }
  }
}
    `;
export const useGetMyDetailsQuery = <
      TData = GetMyDetailsQuery,
      TError = unknown
    >(
      variables?: GetMyDetailsQueryVariables,
      options?: UseQueryOptions<GetMyDetailsQuery, TError, TData>
    ) =>
    useQuery<GetMyDetailsQuery, TError, TData>(
      variables === undefined ? ['getMyDetails'] : ['getMyDetails', variables],
      fetchData<GetMyDetailsQuery, GetMyDetailsQueryVariables>(GetMyDetailsDocument, variables),
      options
    );
export const GetTreatmentVideoThumbnailUploadUrlDocument = `
    query getTreatmentVideoThumbnailUploadUrl {
  content {
    getTreatmentVideoThumbnailUploadUrl {
      url
    }
  }
}
    `;
export const useGetTreatmentVideoThumbnailUploadUrlQuery = <
      TData = GetTreatmentVideoThumbnailUploadUrlQuery,
      TError = unknown
    >(
      variables?: GetTreatmentVideoThumbnailUploadUrlQueryVariables,
      options?: UseQueryOptions<GetTreatmentVideoThumbnailUploadUrlQuery, TError, TData>
    ) =>
    useQuery<GetTreatmentVideoThumbnailUploadUrlQuery, TError, TData>(
      variables === undefined ? ['getTreatmentVideoThumbnailUploadUrl'] : ['getTreatmentVideoThumbnailUploadUrl', variables],
      fetchData<GetTreatmentVideoThumbnailUploadUrlQuery, GetTreatmentVideoThumbnailUploadUrlQueryVariables>(GetTreatmentVideoThumbnailUploadUrlDocument, variables),
      options
    );
export const GetTreatmentVideoUploadUrlDocument = `
    query getTreatmentVideoUploadUrl {
  content {
    getTreatmentVideoUploadUrl {
      url
    }
  }
}
    `;
export const useGetTreatmentVideoUploadUrlQuery = <
      TData = GetTreatmentVideoUploadUrlQuery,
      TError = unknown
    >(
      variables?: GetTreatmentVideoUploadUrlQueryVariables,
      options?: UseQueryOptions<GetTreatmentVideoUploadUrlQuery, TError, TData>
    ) =>
    useQuery<GetTreatmentVideoUploadUrlQuery, TError, TData>(
      variables === undefined ? ['getTreatmentVideoUploadUrl'] : ['getTreatmentVideoUploadUrl', variables],
      fetchData<GetTreatmentVideoUploadUrlQuery, GetTreatmentVideoUploadUrlQueryVariables>(GetTreatmentVideoUploadUrlDocument, variables),
      options
    );
export const GetVideoThumbnailUploadUrlDocument = `
    query getVideoThumbnailUploadUrl {
  content {
    getSleepAidPostVideoUploadUrl {
      url
    }
  }
}
    `;
export const useGetVideoThumbnailUploadUrlQuery = <
      TData = GetVideoThumbnailUploadUrlQuery,
      TError = unknown
    >(
      variables?: GetVideoThumbnailUploadUrlQueryVariables,
      options?: UseQueryOptions<GetVideoThumbnailUploadUrlQuery, TError, TData>
    ) =>
    useQuery<GetVideoThumbnailUploadUrlQuery, TError, TData>(
      variables === undefined ? ['getVideoThumbnailUploadUrl'] : ['getVideoThumbnailUploadUrl', variables],
      fetchData<GetVideoThumbnailUploadUrlQuery, GetVideoThumbnailUploadUrlQueryVariables>(GetVideoThumbnailUploadUrlDocument, variables),
      options
    );
export const GetVideoUploadUrlDocument = `
    query getVideoUploadUrl {
  content {
    getSleepAidPostVideoUploadUrl {
      url
    }
  }
}
    `;
export const useGetVideoUploadUrlQuery = <
      TData = GetVideoUploadUrlQuery,
      TError = unknown
    >(
      variables?: GetVideoUploadUrlQueryVariables,
      options?: UseQueryOptions<GetVideoUploadUrlQuery, TError, TData>
    ) =>
    useQuery<GetVideoUploadUrlQuery, TError, TData>(
      variables === undefined ? ['getVideoUploadUrl'] : ['getVideoUploadUrl', variables],
      fetchData<GetVideoUploadUrlQuery, GetVideoUploadUrlQueryVariables>(GetVideoUploadUrlDocument, variables),
      options
    );
export const GoalSuggestionsDocument = `
    query goalSuggestions($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listGoalSuggestionsWithDetails(meta: $meta, sortDefinition: $sortDefinition) {
      items {
        id
        priority
        details {
          language
          content
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useGoalSuggestionsQuery = <
      TData = GoalSuggestionsQuery,
      TError = unknown
    >(
      variables?: GoalSuggestionsQueryVariables,
      options?: UseQueryOptions<GoalSuggestionsQuery, TError, TData>
    ) =>
    useQuery<GoalSuggestionsQuery, TError, TData>(
      variables === undefined ? ['goalSuggestions'] : ['goalSuggestions', variables],
      fetchData<GoalSuggestionsQuery, GoalSuggestionsQueryVariables>(GoalSuggestionsDocument, variables),
      options
    );
export const ListCategoriesDocument = `
    query listCategories($meta: ListMetadataInput) {
  content {
    listBasicEducationCategories(meta: $meta) {
      items {
        id
        name
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useListCategoriesQuery = <
      TData = ListCategoriesQuery,
      TError = unknown
    >(
      variables?: ListCategoriesQueryVariables,
      options?: UseQueryOptions<ListCategoriesQuery, TError, TData>
    ) =>
    useQuery<ListCategoriesQuery, TError, TData>(
      variables === undefined ? ['listCategories'] : ['listCategories', variables],
      fetchData<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, variables),
      options
    );
export const SleepAidPostsDocument = `
    query sleepAidPosts($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listSleepAidPostsWithDetails(meta: $meta, sortDefinition: $sortDefinition) {
      items {
        id
        priority
        details {
          language
          title
          content
          videoUrl
          videoThumbnailUrl
          videoDuration
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useSleepAidPostsQuery = <
      TData = SleepAidPostsQuery,
      TError = unknown
    >(
      variables?: SleepAidPostsQueryVariables,
      options?: UseQueryOptions<SleepAidPostsQuery, TError, TData>
    ) =>
    useQuery<SleepAidPostsQuery, TError, TData>(
      variables === undefined ? ['sleepAidPosts'] : ['sleepAidPosts', variables],
      fetchData<SleepAidPostsQuery, SleepAidPostsQueryVariables>(SleepAidPostsDocument, variables),
      options
    );
export const SleepHabitSuggestionsDocument = `
    query sleepHabitSuggestions($meta: ListMetadataInput, $sortDefinition: ContentSortDefinitionInput) {
  content {
    listSleepHabitSuggestionsWithDetails(
      meta: $meta
      sortDefinition: $sortDefinition
    ) {
      items {
        id
        priority
        details {
          language
          content
        }
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useSleepHabitSuggestionsQuery = <
      TData = SleepHabitSuggestionsQuery,
      TError = unknown
    >(
      variables?: SleepHabitSuggestionsQueryVariables,
      options?: UseQueryOptions<SleepHabitSuggestionsQuery, TError, TData>
    ) =>
    useQuery<SleepHabitSuggestionsQuery, TError, TData>(
      variables === undefined ? ['sleepHabitSuggestions'] : ['sleepHabitSuggestions', variables],
      fetchData<SleepHabitSuggestionsQuery, SleepHabitSuggestionsQueryVariables>(SleepHabitSuggestionsDocument, variables),
      options
    );
export const GetTreatmentWeekWithDetailsDocument = `
    query getTreatmentWeekWithDetails($treatmentWeekId: TreatmentWeekId!) {
  content {
    getTreatmentWeekWithDetails(treatmentWeekId: $treatmentWeekId) {
      sleepAdvices {
        id
        details {
          title
          content
          language
        }
        priority
      }
      videos {
        id
        details {
          language
          title
          content
          videoUrl
          videoThumbnailUrl
          videoDuration
        }
        priority
      }
    }
  }
}
    `;
export const useGetTreatmentWeekWithDetailsQuery = <
      TData = GetTreatmentWeekWithDetailsQuery,
      TError = unknown
    >(
      variables: GetTreatmentWeekWithDetailsQueryVariables,
      options?: UseQueryOptions<GetTreatmentWeekWithDetailsQuery, TError, TData>
    ) =>
    useQuery<GetTreatmentWeekWithDetailsQuery, TError, TData>(
      ['getTreatmentWeekWithDetails', variables],
      fetchData<GetTreatmentWeekWithDetailsQuery, GetTreatmentWeekWithDetailsQueryVariables>(GetTreatmentWeekWithDetailsDocument, variables),
      options
    );
export const ListTreatmentsWithDetailsDocument = `
    query listTreatmentsWithDetails($sortDefinition: ContentSortDefinitionInput, $meta: ListMetadataInput) {
  content {
    listTreatmentsWithDetails(meta: $meta, sortDefinition: $sortDefinition) {
      items {
        id
        details {
          title
          description
          language
        }
        priority
        weeks {
          id
          number
        }
      }
      meta {
        pageSize
        pageCursor
        nextPageCursor
        totalCount
      }
    }
  }
}
    `;
export const useListTreatmentsWithDetailsQuery = <
      TData = ListTreatmentsWithDetailsQuery,
      TError = unknown
    >(
      variables?: ListTreatmentsWithDetailsQueryVariables,
      options?: UseQueryOptions<ListTreatmentsWithDetailsQuery, TError, TData>
    ) =>
    useQuery<ListTreatmentsWithDetailsQuery, TError, TData>(
      variables === undefined ? ['listTreatmentsWithDetails'] : ['listTreatmentsWithDetails', variables],
      fetchData<ListTreatmentsWithDetailsQuery, ListTreatmentsWithDetailsQueryVariables>(ListTreatmentsWithDetailsDocument, variables),
      options
    );
export const UsersDocument = `
    query users($sortDefinition: IdentitySortDefinitionInput, $meta: ListMetadataInput, $filters: IdentityListFiltersInput) {
  identity {
    list(sortDefinition: $sortDefinition, meta: $meta, filters: $filters) {
      items {
        id
        name
        email
        profilePictureUrl
        registeredAt
        isBanned
        isEmailConfirmed
      }
      meta {
        ...Meta
      }
    }
  }
}
    ${MetaFragmentDoc}`;
export const useUsersQuery = <
      TData = UsersQuery,
      TError = unknown
    >(
      variables?: UsersQueryVariables,
      options?: UseQueryOptions<UsersQuery, TError, TData>
    ) =>
    useQuery<UsersQuery, TError, TData>(
      variables === undefined ? ['users'] : ['users', variables],
      fetchData<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
      options
    );