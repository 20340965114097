import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "../../components/Dialog/Dialog";
import { Form } from "../../components/Form/Form";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { SxStyles } from "../../theme";
import { SleepAidPost, SleepAidPostDialogProps, VideoModalProps } from "./PageSleepAidPosts.types";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ValidationMessage } from "../../utilities/enums";
import {
  GetVideoThumbnailUploadUrlQuery,
  GetVideoUploadUrlQuery,
  useCreateSleepAidPostMutation,
  useGetVideoThumbnailUploadUrlQuery,
  useGetVideoUploadUrlQuery,
  useUpdateSleepAidPostMutation,
} from "../../graphql/client";
import { LoadingButton } from "@mui/lab";
import { handlePostFormSubmit } from "./helpers";
import { sx as sxStyles } from "../../helpers/sx";
import { useHandleError } from "../../hooks/useHandleError";

export function SleepAidPostDialog({
  isOpen,
  handleClose,
  editData,
  rowsRefetch,
}: SleepAidPostDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SleepAidPost>();

  const { openSnack } = useAlertContext();
  const handleError = useHandleError();

  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const { data: videoThumbnailUploadUrlData, refetch: refetchThumbnailUploadUrl } =
    useGetVideoThumbnailUploadUrlQuery<GetVideoThumbnailUploadUrlQuery>({});

  const { data: videoUploadUrlData, refetch: refetchVideoUploadUrl } =
    useGetVideoUploadUrlQuery<GetVideoUploadUrlQuery>({});

  const { mutate: createMutate, isLoading: isLoadingCreate } = useCreateSleepAidPostMutation({
    onError: (e: Error) => handleCreateError(e),
    onSuccess: () => handleCreateSuccess(),
  });

  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useUpdateSleepAidPostMutation({
    onError: (e: Error) => handleUpdateError(e),
    onSuccess: () => handleUpdateSuccess(),
  });

  const handleCreateSuccess = () => {
    setIsMutationLoading(false);
    handleCloseDialog();
    reset();
    rowsRefetch();
    openSnack({ type: "success", info: "Post created" });
  };

  const handleCreateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to create post.");
  };

  const handleUpdateSuccess = () => {
    setIsMutationLoading(false);
    reset();
    handleCloseDialog();
    rowsRefetch();
    openSnack({ type: "success", info: "Post updated" });
  };

  const handleUpdateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to update post.");
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  useEffect(() => {
    reset();
    if (editData) {
      setValue("englishTitle", editData.englishTitle);
      setValue("englishContent", editData.englishContent);
      setValue("englishVideoThumbnail", [new File([""], "current video thumbnail")]);
      setValue("englishVideo", [new File([], "current video")]);
      setValue("englishVideoDuration", editData.englishVideoDuration);
      setValue("icelandicTitle", editData.icelandicTitle);
      setValue("icelandicContent", editData.icelandicContent);
      setValue("icelandicVideoThumbnail", [new File([""], "current video thumbnail")]);
      setValue("icelandicVideo", [new File([], "current video")]);
      setValue("icelandicVideoDuration", editData.icelandicVideoDuration);
      setValue("priority", editData.priority);
    }
  }, [editData, reset, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    setIsMutationLoading(true);

    if (videoThumbnailUploadUrlData && videoUploadUrlData) {
      const { priority, ...obj } = data;
      const formattedData = { priority: Number(priority), ...obj };

      handlePostFormSubmit(
        editData,
        createMutate,
        updateMutate,
        videoThumbnailUploadUrlData,
        videoUploadUrlData,
        formattedData,
        refetchThumbnailUploadUrl,
        refetchVideoUploadUrl
      );
    }
  });

  const PushNotificationInfo = () => (
    <Typography sx={sx.info}>(Push notification will be sent to users)</Typography>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      title={editData ? "Sleep aid post update" : "Create new Sleep aid post"}
    >
      {!editData && <PushNotificationInfo />}
      <DialogContent sx={sx.dialogContent}>
        <Box sx={sx.dialogContentContainer}>
          <Form onSubmit={onSubmit}>
            <Box sx={sx.formContainer}>
              <Box sx={sx.inputContainer}>
                <Typography sx={sx.dialogLabel}>English</Typography>
                <TextField
                  sx={sx.title}
                  label="Title"
                  variant="standard"
                  placeholder="Title"
                  fullWidth
                  {...register("englishTitle", {
                    required: true,
                  })}
                  error={!!errors.englishTitle}
                  helperText={!!errors.englishTitle && ValidationMessage.Required}
                />
                <TextField
                  label="Content"
                  variant="outlined"
                  placeholder="Content"
                  multiline
                  rows={7}
                  {...register("englishContent", {
                    required: true,
                  })}
                  error={!!errors.englishContent}
                  helperText={!!errors.englishContent && ValidationMessage.Required}
                />
                <Box sx={sx.multimediaInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    <Typography sx={sx.multimediaLabel}>Upload Video</Typography>
                    <input
                      id="enVideo"
                      type="file"
                      accept="video/*"
                      alt="englishVideo"
                      hidden
                      {...register("englishVideo", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.englishVideo && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}
                  {watch("englishVideo")?.[0]?.name && (
                    <Typography sx={sx.multimediaLabel}>
                      {watch("englishVideo")?.[0]?.name}
                    </Typography>
                  )}
                </Box>

                <Box sx={sx.multimediaInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    <Typography sx={sx.multimediaLabel}>Upload Video Thumbnail</Typography>
                    <input
                      type="file"
                      accept="image/*"
                      alt="englishVideoThumbnail"
                      hidden
                      {...register("englishVideoThumbnail", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.englishVideoThumbnail && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}
                  {watch("englishVideoThumbnail")?.[0]?.name && (
                    <Typography sx={sx.multimediaLabel}>
                      {watch("englishVideoThumbnail")?.[0]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={sx.inputContainer}>
                <Typography sx={sx.dialogLabel}>Icelandic</Typography>
                <TextField
                  sx={sx.title}
                  label="Title"
                  variant="standard"
                  placeholder="Title"
                  fullWidth
                  {...register("icelandicTitle", {
                    required: true,
                  })}
                  error={!!errors.icelandicTitle}
                  helperText={!!errors.icelandicTitle && ValidationMessage.Required}
                />
                <TextField
                  label="Content"
                  variant="outlined"
                  placeholder="Content"
                  multiline
                  rows={7}
                  {...register("icelandicContent", {
                    required: true,
                  })}
                  error={!!errors.icelandicContent}
                  helperText={!!errors.icelandicContent && ValidationMessage.Required}
                />
                <Box sx={sx.multimediaInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    <Typography sx={sx.multimediaLabel}>Upload Video</Typography>
                    <input
                      type="file"
                      accept="video/*"
                      alt="icelandicVideo"
                      hidden
                      {...register("icelandicVideo", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.icelandicVideo && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}
                  {watch("icelandicVideo")?.[0]?.name && (
                    <Typography sx={sx.multimediaLabel}>
                      {watch("icelandicVideo")?.[0]?.name}
                    </Typography>
                  )}
                </Box>
                <Box sx={sx.multimediaInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    <Typography sx={sx.multimediaLabel}>Upload Video Thumbnail</Typography>
                    <input
                      type="file"
                      accept="image/*"
                      alt="icelandicVideoThumbnail"
                      hidden
                      {...register("icelandicVideoThumbnail", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.icelandicVideoThumbnail && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}
                  {watch("icelandicVideoThumbnail")?.[0]?.name && (
                    <Typography sx={sx.multimediaLabel}>
                      {watch("icelandicVideoThumbnail")?.[0]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <TextField
              sx={sxStyles.priorityInput}
              label="Priority"
              variant="outlined"
              type="number"
              placeholder="0"
              {...register("priority", {
                min: 0,
              })}
              error={!!errors.priority}
              helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
            />
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                variant="text"
                sx={sxStyles.cancelButton}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoadingCreate || isLoadingUpdate || isMutationLoading}
                disabled={isLoadingCreate || isLoadingUpdate || isMutationLoading}
                type="submit"
                variant="contained"
              >
                {editData ? "Update" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export const VideoModal = ({ url, title, open, handleClose }: VideoModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={title}
    >
      <Box sx={sx.videoContainer}>
        <Box sx={sx.videoContent}>
          <video
            width="100%"
            height="100%"
            controls
            autoPlay
          >
            <source
              src={url}
              type="video/mp4"
            />
          </video>
        </Box>
      </Box>
    </Dialog>
  );
};

const sx: SxStyles = {
  dialogLabel: { fontWeight: 600 },
  formContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "60px",
    py: "20px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "100%",
    width: "400px",
    mx: "auto",
  },
  dialogContent: {
    overflowX: "hidden",
    maxWidth: "100%",
  },
  dialogContentContainer: { mx: "20px" },
  multimediaLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    maxWidth: "180px",
    maxHeight: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  multimediaInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "bottom",
    justifyContent: "end",
    gap: "7px",
  },
  uploadButton: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    width: "100%",
  },
  duration: { maxWidth: "120px" },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  videoContent: {
    width: "100%",
    aspectRatio: "16 / 9",
    overflow: "hidden",
    px: "15px",
    pb: "15px",
  },
  info: { opacity: "0.5", mx: "auto" },
};
