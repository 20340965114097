import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useForm } from "react-hook-form";
import { SxStyles } from "../../theme";
import { Category, CategoryDialogProps, ImageModalProps } from "./PageEduCategories.types";
import { Form } from "../../components/Form/Form";
import { Dialog } from "../../components/Dialog/Dialog";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { ValidationMessage } from "../../utilities/enums";
import { useEffect } from "react";
import {
  GetCategoryImgUrlQuery,
  useCreateCategoryMutation,
  useGetCategoryImgUrlQuery,
  useUpdateCategoryMutation,
} from "../../graphql/client";
import { LoadingButton } from "@mui/lab";
import { MediaType, uploadMedia } from "../../helpers/mediaUploader";
import { useHandleError } from "../../hooks/useHandleError";
import { sx as style } from "../../helpers/sx";

export function CategoryDialog({
  isOpen,
  handleClose,
  editData,
  rowsRefetch,
}: CategoryDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Category>();

  const handleError = useHandleError();

  const { data: imageUploadUrlData, refetch } = useGetCategoryImgUrlQuery<GetCategoryImgUrlQuery>(
    {}
  );

  const { mutate: createCategoryMutate, isLoading: isLoadingCreate } = useCreateCategoryMutation({
    onError: (e: Error) => handleCreateCategoryError(e),
    onSuccess: () => handleCreateCategorySuccess(),
  });

  const { mutate: updateCategoryMutate, isLoading: isLoadingUpdate } = useUpdateCategoryMutation({
    onError: (e: Error) => handleUpdateCategoryError(e),
    onSuccess: () => handleUpdateCategorySuccess(),
  });

  const handleCreateCategorySuccess = () => {
    reset();
    handleCloseDialog();
    rowsRefetch();
    openSnack({ type: "success", info: "Category created" });
  };

  const handleCreateCategoryError = (e: Error) => {
    handleError(e, "Failed to create category.");
  };

  const handleUpdateCategorySuccess = () => {
    reset();
    handleCloseDialog();
    rowsRefetch();
    openSnack({ type: "success", info: "Category updated" });
  };

  const handleUpdateCategoryError = (e: Error) => {
    handleError(e, "Failed to update category.");
  };

  useEffect(() => {
    if (editData) {
      setValue("englishName", editData.englishName);
      setValue("englishBackgroundImage", [new File([], "current image")]);
      setValue("icelandicName", editData.icelandicName);
      setValue("icelandicBackgroundImage", [new File([], "current image")]);
      setValue("priority", editData.priority);
    } else {
      reset();
    }
  }, [editData, reset, setValue]);

  const { openSnack } = useAlertContext();

  const onSubmit = handleSubmit(async (data) => {
    const englishImage = data.englishBackgroundImage?.[0];
    const icelandicImage = data.icelandicBackgroundImage?.[0];

    let imageCreateUrlEnglish;
    let imageCreateUrlIcelandic;

    const firstUploadUrl =
      imageUploadUrlData?.content.getBasicEducationCategoryBackgroundImageUploadUrl.url;

    let urls;

    if (englishImage.size > 0) {
      if (firstUploadUrl) {
        urls = await uploadMedia<GetCategoryImgUrlQuery>(
          MediaType.image,
          englishImage,
          firstUploadUrl,
          refetch
        );
      }
      imageCreateUrlEnglish = urls?.mediaUrl;
    }

    if (icelandicImage.size > 0) {
      if (urls?.uploadUrl) {
        const newUploadUrl =
          urls?.uploadUrl.content.getBasicEducationCategoryBackgroundImageUploadUrl.url;
        urls = await uploadMedia<GetCategoryImgUrlQuery>(
          MediaType.image,
          icelandicImage,
          newUploadUrl,
          refetch
        );
      } else if (firstUploadUrl) {
        urls = await uploadMedia<GetCategoryImgUrlQuery>(
          MediaType.image,
          icelandicImage,
          firstUploadUrl,
          refetch
        );
      }
      imageCreateUrlIcelandic = urls?.mediaUrl;
    }

    if (editData) {
      updateCategoryMutate({
        input: {
          id: editData.id,
          englishBackgroundImageUrl: imageCreateUrlEnglish ?? editData?.englishBackgroundImage,
          englishName: getValues("englishName"),
          icelandicBackgroundImageUrl:
            imageCreateUrlIcelandic ?? editData?.icelandicBackgroundImage,
          icelandicName: getValues("icelandicName"),
          priority: Number(getValues("priority")),
        },
      });
    } else {
      if (imageCreateUrlEnglish && imageCreateUrlIcelandic) {
        createCategoryMutate({
          input: {
            englishBackgroundImageUrl: imageCreateUrlEnglish,
            englishName: getValues("englishName"),
            icelandicBackgroundImageUrl: imageCreateUrlIcelandic,
            icelandicName: getValues("icelandicName"),
            priority: Number(getValues("priority")),
          },
        });
      }
    }
  });

  const handleCloseDialog = () => {
    handleClose();
  };

  const PushNotificationInfo = () => (
    <Typography sx={sx.info}>(Push notification will be sent to users)</Typography>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      title={editData ? "Category update" : "Create new category"}
      size="md"
      sx={{ maxWidth: { xs: "450px", sm: "550px" } }}
    >
      <DialogContent sx={sx.newCatForm}>
        {!editData && <PushNotificationInfo />}
        <Form onSubmit={onSubmit}>
          <Box sx={sx.formContainer}>
            <Box>
              <Typography sx={sx.dialogLabel}>English</Typography>
              <Box sx={sx.inputs}>
                <TextField
                  fullWidth
                  label="Name"
                  {...register("englishName", {
                    required: true,
                  })}
                  error={!!errors.englishName}
                  helperText={!!errors.englishName && ValidationMessage.Required}
                />
                <Box sx={sx.imageInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    Upload Background
                    <input
                      type="file"
                      accept="image/*"
                      alt="background image"
                      hidden
                      {...register("englishBackgroundImage", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.englishBackgroundImage && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}

                  {watch("englishBackgroundImage")?.[0]?.name && (
                    <Typography sx={sx.imageName}>
                      {watch("englishBackgroundImage")?.[0]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography sx={sx.dialogLabel}>Icelandic</Typography>
              <Box sx={sx.inputs}>
                <TextField
                  fullWidth
                  label="Name"
                  {...register("icelandicName", {
                    required: true,
                  })}
                  error={!!errors.icelandicName}
                  helperText={!!errors.icelandicName && ValidationMessage.Required}
                />
                <Box sx={sx.imageInputContainer}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={sx.uploadButton}
                  >
                    <FileUploadIcon />
                    Upload Background
                    <input
                      type="file"
                      accept="image/*"
                      alt="background image"
                      hidden
                      {...register("icelandicBackgroundImage", {
                        required: !editData,
                      })}
                    />
                  </Button>
                  {!!errors.icelandicBackgroundImage && (
                    <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
                  )}
                  {watch("icelandicBackgroundImage")?.[0]?.name && (
                    <Typography sx={sx.imageName}>
                      {watch("icelandicBackgroundImage")?.[0]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={sx.inputContainer}>
              <TextField
                sx={style.priorityInput}
                label="Priority"
                variant="outlined"
                type="number"
                placeholder="0"
                {...register("priority", {
                  min: 0,
                })}
                error={!!errors.priority}
                helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
              />
            </Box>
            <DialogActions sx={sx.buttons}>
              <Button
                onClick={handleCloseDialog}
                variant="text"
                sx={sx.cancelButton}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoadingCreate || isLoadingUpdate}
                disabled={isLoadingCreate || isLoadingUpdate}
                type="submit"
                variant="contained"
              >
                {editData ? "Update" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const ImageModal = ({ openedImage, handleCloseImage }: ImageModalProps) => (
  <MuiDialog
    open={Boolean(openedImage)}
    onClose={handleCloseImage}
    sx={sx.imageModal}
  >
    <Box
      component="img"
      sx={{
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
      }}
      src={openedImage}
    />
  </MuiDialog>
);

const sx: SxStyles = {
  newCatForm: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    maxWidth: "100%",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    p: "20px",
  },
  inputs: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "center",
    gap: { xs: "15px", sm: "30px" },
  },
  dialogLabel: { fontWeight: 600 },
  buttons: { m: "20px 0 0 auto", p: 0 },
  submitButton: {
    backgroundColor: "navyBlueDark.main",
    color: "common.white",
    "&:hover": { backgroundColor: "darkBlue.main" },
  },
  cancelButton: {
    color: "common.black",
    "&:hover": { textDecoration: "underline" },
    fontWeight: 600,
  },
  errorMsg: { color: "error.main", fontSize: "0.75rem" },
  imageModal: {
    maxWidth: "1200px",
    mx: "auto",
  },
  imageName: {
    fontSize: "0.875rem",
    fontWeight: 600,
    maxWidth: "180px",
    maxHeight: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  imageInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "bottom",
    justifyContent: "end",
    width: "100%",
    gap: "7px",
  },
  uploadButton: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "5px",
    textAlign: "center",
  },
  info: { opacity: "0.5", mx: "auto" },
};
