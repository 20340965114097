import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Paper } from "@mui/material";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { useEffect, useState } from "react";
import { SleepAidPostDialog, VideoModal } from "./SleepAidPostsModals";
import { SleepAidPostDataModel, Video } from "./PageSleepAidPosts.types";
import { formatSleepAidPosts, useColumns } from "./helpers";
import {
  ContentSortField,
  Language,
  SortDirection,
  useSleepAidPostsQuery,
} from "../../graphql/client";
import { useServerPagination } from "../../hooks/useDataGrid";
import { useHandleError } from "../../hooks/useHandleError";
import { useForm } from "react-hook-form";
import { LanguageOptions } from "../../hooks/types";
import { TableFilters } from "../../components/TableFilters/TableFilters";
import { LanguageSelect } from "../../components/LanguageSelect";
import { sx } from "../../helpers/sx";
import { handleChangeSortDirection } from "../../helpers/sortHandler";

export const PageSleepAidPosts = () => {
  const [pageSize, setPageSize] = useState(8);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState<SleepAidPostDataModel | null>(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [video, setVideo] = useState<Video | null>(null);

  const handleError = useHandleError();

  const { control, watch } = useForm<LanguageOptions>({
    defaultValues: {
      language: Language.English,
    },
  });

  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);

  const { data, refetch, isFetching } = useSleepAidPostsQuery(
    {
      sortDefinition: {
        contentSortField: ContentSortField.Priority,
        sortDirection: sortDirection,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
    },
    { select: formatSleepAidPosts, keepPreviousData: true, onError: handleError }
  );

  const { handlePageChange, currentCursor, page, resetPages } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const handleStartEdit = (obj: SleepAidPostDataModel) => {
    setEditData(obj);
    handleOpenDialog();
  };

  const handleOpenVideoModal = ({ url, title }: Video) => {
    setVideo({ url, title });
    setIsVideoModalOpen(true);
  };

  const columns = useColumns(handleStartEdit, handleOpenVideoModal, watch("language"), refetch);

  const handleDisableEdit = () => {
    setEditData(null);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  return (
    <Container>
      <Header>Sleep Aid Posts</Header>
      <SleepAidPostDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        editData={editData}
        rowsRefetch={refetch}
      />
      <VideoModal
        open={isVideoModalOpen}
        handleClose={handleCloseVideoModal}
        title={video?.title || ""}
        url={video?.url || ""}
      />
      <Paper sx={sx.table}>
        <Box>
          <Button
            onClick={() => {
              handleDisableEdit();
              handleOpenDialog();
            }}
          >
            Create new post
          </Button>
        </Box>
        <TableFilters>
          <LanguageSelect control={control} />
        </TableFilters>
        <DataGrid
          sortingMode="server"
          onColumnHeaderClick={(event) =>
            handleChangeSortDirection(event, setSortDirection, resetPages)
          }
          onSortModelChange={resetPages}
          columns={columns}
          rows={data?.rows || []}
          paginationMode="server"
          pageSize={pageSize}
          rowCount={data?.meta.totalCount || 0}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          rowsPerPageOptions={[8, 16, 32]}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
};
