import { GridColDef } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import { CategoriesQuery, Language, useDeleteCategoryMutation } from "../../graphql/client";
import { CategoryDataModel, UseEducationCategoriesReturn } from "./PageEduCategories.types";
import { OverflowTooltip } from "../../components/OverflowTooltip/OverflowTooltip";
import { CSSProperties } from "react";
import { sx } from "../../helpers/sx";
import { useHandleMutationResponse } from "../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../hooks/types";
import { columnPriority } from "../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (category: CategoryDataModel) => void,
  handleOpenImage: (url: string) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteCategoryMutate } = useDeleteCategoryMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Category deleted"),
  });

  const createOptions = (obj: CategoryDataModel) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete category",
            text: "Are you sure?",
          }).then(() => {
            deleteCategoryMutate({ input: { id: obj.id } });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}Name`];
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    {
      field: "bgUrl",
      headerName: "Background",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}BackgroundImage`];
        return (
          <Button
            variant="text"
            onClick={() => handleOpenImage(value)}
          >
            <img
              src={value}
              alt="background"
              style={sx.image as CSSProperties}
            />
          </Button>
        );
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(params.row)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatEducationCategories = (data: CategoriesQuery): UseEducationCategoriesReturn => {
  const categories = data.content.listBasicEducationCategoriesWithDetails.items;
  let categoryObj: CategoryDataModel;
  let rowsArray: CategoryDataModel[] = categories?.map((category) => {
    if (category.details[0]?.language === Language.English) {
      categoryObj = {
        id: category.id.toString(),
        englishName: category.details[0].name,
        englishBackgroundImage: category.details[0].backgroundImageUrl,
        icelandicName: category.details[1]?.name || "",
        icelandicBackgroundImage: category.details[1]?.backgroundImageUrl || "",
        priority: category.priority,
      };
    } else {
      categoryObj = {
        id: category.id.toString(),
        englishName: category.details[1]?.name || "",
        englishBackgroundImage: category.details[1]?.backgroundImageUrl || "",
        icelandicName: category.details[0]?.name || "",
        icelandicBackgroundImage: category.details[0]?.backgroundImageUrl || "",
        priority: category.priority,
      };
    }
    return categoryObj;
  });

  return { rows: rowsArray, meta: data.content.listBasicEducationCategoriesWithDetails.meta };
};
