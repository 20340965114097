import { GridColDef } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuActions } from "../../../components/MenuActions/MenuActions";
import { MenuOption } from "../../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialog/ConfirmationDialog";
import { TextContentButton } from "../../../components/TextContentButton/TextContentButton";
import { OverflowTooltip } from "../../../components/OverflowTooltip/OverflowTooltip";
import {
  Language,
  SleepHabitSuggestionsQuery,
  useDeleteSleepHabitSuggestionMutation,
} from "../../../graphql/client";
import { ContentFormData } from "../../../components/ContentFormDialog/ContentFormDialog.types";
import {
  SleepHabitSuggestionDataModel,
  UseSleepHabitSuggestionsReturn,
} from "./PageSleepHabitSuggestions.types";
import { useHandleMutationResponse } from "../../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../../hooks/types";
import { columnPriority } from "../../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (obj: ContentFormData) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteCategoryMutate } = useDeleteSleepHabitSuggestionMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Suggestion deleted"),
  });

  const createOptions = (obj: ContentFormData) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete suggestion",
            text: "Are you sure?\n(all translations will be deleted)",
          }).then(() => {
            deleteCategoryMutate({ sleepHabitSuggestionId: obj.id });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "content",
      headerName: "Content",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <TextContentButton content={params.row[`${language.toLowerCase()}Content`]} />;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const formObj: ContentFormData = {
          id: params.row.id,
          englishFieldFirst: params.row.englishContent,
          icelandicFieldFirst: params.row.icelandicContent,
          priority: params.row.priority,
        };
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(formObj)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatSleepHabitSuggestions = (
  data: SleepHabitSuggestionsQuery
): UseSleepHabitSuggestionsReturn => {
  const suggestions = data.content.listSleepHabitSuggestionsWithDetails.items;
  let suggestionObj: SleepHabitSuggestionDataModel;
  let rowsArray: SleepHabitSuggestionDataModel[] = suggestions?.map((suggestion) => {
    if (suggestion.details[0]?.language === Language.English) {
      suggestionObj = {
        id: suggestion.id.toString(),
        englishContent: suggestion.details[0].content,
        icelandicContent: suggestion.details[1]?.content || "",
        priority: suggestion.priority,
      };
    } else {
      suggestionObj = {
        id: suggestion.id.toString(),
        englishContent: suggestion.details[1]?.content || "",
        icelandicContent: suggestion.details[0]?.content || "",
        priority: suggestion.priority,
      };
    }

    return suggestionObj;
  });

  return { rows: rowsArray, meta: data.content.listSleepHabitSuggestionsWithDetails.meta };
};
